import React, { useState, useEffect } from "react";
import { image } from "../../common/Theme";

import { useNavigate } from "react-router-dom";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../utils/Loader";
import Apiconnection from "../../utils/Apiconnection";

const Login = () => {

  const [email, setemail] = useState("")
  const [password, setpassword] = useState("")
  const [loading, setloading] = useState(false)



  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async () => {

    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

        if (reg.test(email) === false) {

            toast.error('Email should be proper!');
        } else if(password == ""){
            toast.error('Password should be must');
        } else {
            setloading(true)

            let payload = {
              "email": email,
              "password": password,
              "deviceType": 2,
              "platform": "AdminPanel"
            };

            try{
              const response = await Apiconnection.post('login', payload)
              setloading(false)
              if(response?.data?.status){
                localStorage.setItem('prime_access_token', response?.data?.data?.user?.webLogin)
                localStorage.setItem('userInfo', JSON.stringify(response?.data?.data?.user));
                toast.success("Logged In Successfully");
                navigate('/dashboard')
                window.location.reload()

              } else {
                toast.error(response?.data?.message); 
                navigate('/dashboard')

              }
             
          } catch(err){
              setloading(false)
              if(err.response?.status === 401){
              toast.error(err.response?.data?.message);   
               } else if(err.response?.status === 400){
                toast.error(err.response?.data?.message);   
                 }
      }
        }



  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  }

  return (
    <>
    <ToastContainer />
    {loading && <Loader/>}
      <div className="admin-login-sec">
        <div className="login-form-sec">
          <div className="login-box">
            <img className="logo-img" src={image.logo} alt="" />
            <h4>Admin Login</h4>
            <form action="">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group mb-4">
                      <span className="icn-input"><img src={image.usericn} alt="" /></span>
                      <input className="form-control" type="email" name="email" 
                        placeholder="User Email ID" value={email}
                        onChange={(e)=>setemail(e.target.value)} 
                        onKeyPress={handleKeyPress} />
                    </div>
           
                  </div>



                  <div className="col-md-12">
                    <div className="form-group mb-4">
                      <span className="icn-input"><img src={image.passicn} alt="" /></span>
                      <input className="form-control" type={showPassword ? "text" : "password"} name="password"
                         placeholder="Password"    value={password}
                         onChange={(e)=>setpassword(e.target.value)}
                         onKeyPress={handleKeyPress} />
                      <span className="eye-icon" onClick={handleTogglePasswordVisibility}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </span>
                    </div>
                  
                  </div>
                  <div className="col-12 mb-4">
                    <button className="mb-3 btn thm-btn" type="button" onClick={handleSubmit}>CONTINUE </button>

                  </div>
                </div>
              </div>
            </form>
            <a href="#" className="forget-pswrd">Forgot Password?</a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;