import React from 'react'

const TableLoader = () => {
  return (
    <>
    <div class="card__skeleton card__title"></div>
    <div class="card__skeleton card__title"></div>
    <div class="card__skeleton card__title"></div>
</>
  )
}

export default TableLoader