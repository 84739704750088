import React, { useEffect, useState } from 'react';
import { image } from "../../src/common/Theme";
import { useDispatch } from "react-redux";
import { logout, setUser } from "../views/Login/userSlice";
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { fetchSidebar } from '../views/dashboard/api';
import { logOut } from './api';
import Apiconnection from '../utils/Apiconnection';
import Loader from '../utils/Loader';

function Sidebar({ openSidebarToggle, OpenSidebar }) {
  const [sidebarMenu, setSidebarMenu] = useState([]);
  const [loading, setloading] = useState(false)
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  // const handleLogout = async () => {
  //   try {
  //     const response = await logOut();
  //     if (response.status === 200) {
  //       dispatch(logout());
  //       navigate('/login');
  //     }
  //   } catch (error) {
  //     console.log('error', error);
  //   }
  // };

  const handleLogout = async () => {
    localStorage.clear()
    window.location.reload()
  };

  useEffect(() => {
    const getSidebar = async () => {
      setloading(true)
      try {
        const response = await Apiconnection.post('module-list');
        setloading(false)
        if (response?.data?.status) {
          setSidebarMenu(response?.data?.data);
        }

      } catch (error) {
        setloading(false)
        console.log('error', error);
        if (error.response?.status === 401) {
          localStorage.clear()
          navigate('/login')

        }
      }
    };
    getSidebar();
  }, []);


  const renderSubMenu = (menuItem, index) => {
    if (menuItem.parentModuleId === null) {
      const subMenuItems = sidebarMenu.filter(subMenuItem => subMenuItem.parentModuleId === menuItem.id);
      return (
        <li key={index} className={`sidebar-list-item ${subMenuItems.length > 0 ? 'has-submenu' : ''} ${location.pathname === `/${menuItem.moduleSlug}` ? 'active' : ''}`}>
          <Link to={`/${menuItem.moduleSlug}`}>
            <div className="icon"> <img src={menuItem.moduleIcon} alt="" /></div> {menuItem.moduleName}
          </Link>
          {subMenuItems.length > 0 && (
            <ul>
              {subMenuItems.map((subMenuItem, subIndex) => (
                <li key={subIndex} className={`sidebar-list-item ${location.pathname === `/${subMenuItem.moduleSlug}` ? 'active' : ''}`}>
                  <Link to={`/${subMenuItem.moduleSlug}`}>
                    <div className="icon"> <img src={subMenuItem.moduleIcon} alt="" /></div> {subMenuItem.moduleName}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </li>
      );
    }
    return null;
  };

  return (
    <aside id="sidebar" className={openSidebarToggle ? "sidebar-responsive" : ""}>
      {loading && <Loader />}
      <div className='sidebar-title'>
        <div className='sidebar-brand'>
          <img className="logo-img" src={image.logo} alt="" />
        </div>
        <span className='icon close_icon' onClick={OpenSidebar}>X</span>
      </div>

      <ul className='sidebar-list'>
        <li className='sidebar-list-item'>
        <NavLink herf="#" to="/dashboard">
          <div className="icon"> <img src={image.dashboard} alt="" /></div> Dashboard
        </NavLink>
        </li>

        {sidebarMenu.map((menuItem, index) => renderSubMenu(menuItem, index))}

        <li className='sidebar-list-item' onClick={handleLogout}>
          <div className="icon"> <img src={image.logout} alt="" /></div> Logout
        </li>
      </ul>
    </aside>
  );
}

export default Sidebar;