import React from 'react';
import { BsFillBellFill, BsFillEnvelopeFill, BsPersonCircle, BsSearch, BsJustify } from 'react-icons/bs';
import { image } from "../../src/common/Theme";
import { useLocation } from 'react-router-dom';

function Header({ OpenSidebar }) {
  const location = useLocation();

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  // console.log(userInfo)


  let pageTitle = '';
  switch (location.pathname) {
    case '/dashboard':
      pageTitle = 'Dashboard';
      break;
    case '/users':
      pageTitle = 'User Management';
      break;
    case '/bookings':
      pageTitle = 'Booking';
      break;
    case '/payments':
      pageTitle = 'Payments';
      break;
    case '/policies':
      pageTitle = 'Policy';
      break;
    case '/reports':
      pageTitle = 'Policy';
      break;
    case '/vendors':
      pageTitle = 'Vendor Management';
      break;
      case '/agents':
        pageTitle = 'Agent Management';
        break;
    case '/parking-slots':
      pageTitle = 'Parking Slots';
      break;
    case '/rate-chart':
      pageTitle = 'Rate Chart';
      break;
    case '/notifications':
      pageTitle = 'Notification';
      break;

    case '/car-movements':
      pageTitle = 'Car Movements';
      break;

    case '/revenue-report':
      pageTitle = 'Revenue Report';
      break;

      case '/add-booking':
        pageTitle = 'Booking';
        break;
        case '/customer-booking':
          pageTitle = 'Booking';
          break;
          case '/booking-details':
            pageTitle = 'Booking';
            break;
            case '/booking-receipts':
              pageTitle = 'Booking';
              break;

    default:
      pageTitle = '';
  }

  return (
    <header className='header'>
      <div className='menu-icon'>
        <BsJustify className='icon' onClick={OpenSidebar} />
      </div>
      <div className='header-left'>
        <h3>{pageTitle}</h3>
      </div>
      <div className='header-right'>
        <div className='user-details'>
          <img src={image.userimage} alt="" />{userInfo?.firstName}
        </div>
        <a href="#" className='header-notification'><img src={image.notificationbell} alt="" /></a>
      </div>
    </header>
  );
}

export default Header;