import React, { useEffect, useState } from "react";
import Header from '../../component/Header';
import Sidebar from '../../component/SideBar';
import {
    Table, TableBody, Modal, TableCell, TableContainer, TableHead, TableRow, Paper,
    TextField, Select, MenuItem, Button, Toolbar, Typography, FormControl, InputLabel, TablePagination, FormHelperText
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useNavigate } from 'react-router-dom';
import Apiconnection from "../../utils/Apiconnection";
import { toast } from "react-toastify";
import dayjs from "dayjs";

const BookingInvoice = () => {
    const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [openModal, setOpenModal] = useState(false);
    const [bookings, setBookings] = useState([]);
    const [errors, setErrors] = useState({});
    const [openCheckOutModal, setOpenCheckOutModal] = useState(false);

    const OpenSidebar = () => {
        setOpenSidebarToggle(!openSidebarToggle);
    };
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch bookings or any required data here
    }, []);

    return (
        <div className='grid-container'>
            <Header OpenSidebar={OpenSidebar} />
            <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar}/>
            <div className='inner-container'>
                <div className='custom-table'>
                    <table className="top-table" style={{ width: '800px', backgroundColor: '#fff', padding: '40px', margin: '40px auto' }}>
                        <tbody>
                            <tr>
                                <td className="cmn-width">
                                    <table className="common-table">
                                        <tbody>
                                            <tr>
                                                <td style={{ padding: 0 }}>
                                                    <div className="bg-black">
                                                        <h5>Prime Park Receipt</h5>
                                                        <p>+918298738900</p>
                                                        <p>206 S Weller St, Seattle, WA 98104, United States</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table className="body-top">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <div className="check-in">
                                                                        <p>Check In Date & Time</p>
                                                                        <h3>09 Sept 2024 | 10:45</h3>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="check-in">
                                                                        <p>Check Out Date & Time</p>
                                                                        <h3>09 Sept 2024 | 10:45</h3>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div className="check-in">
                                                                        <p>Name</p>
                                                                        <h3>Indranil Sen</h3>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="check-in">
                                                                        <p>Phone number</p>
                                                                        <h3>6291332961</h3>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div className="check-in">
                                                                        <p>Ticket ID</p>
                                                                        <h3>163</h3>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="check-in">
                                                                        <p>Slot Number</p>
                                                                        <h3>48L-6</h3>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div className="check-in">
                                                                        <p>Payment</p>
                                                                        <h3>Cash</h3>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="check-in">
                                                                        <p>Cash</p>
                                                                        <h3>$400</h3>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <table className="billing-details">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <h2>Billing Details</h2>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <p>Onsite amount charges</p>
                                                                </td>
                                                                <td>
                                                                    <h5>$0</h5>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div className="parking-charge">
                                                                        <p>Parking Charges</p>
                                                                        <span>(13 days * $30 - Truck)</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <h5>$390</h5>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div className="parking-charge">
                                                                        <p>Overstay charges</p>
                                                                        <span>(0 days)</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <h5>$0</h5>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div className="parking-charge">
                                                                        <p>Extra Passenger Fees</p>
                                                                        <span>(5 Passengers)</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <h5>$10</h5>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <table className="bottom-table">
                                                        <tbody>
                                                            <tr className="bg-black">
                                                                <td style={{ width: '50%' }}>
                                                                    <h5 style={{ textAlign: 'left' }}>Total amount to pay</h5>
                                                                </td>
                                                                <td style={{ width: '50%' }}>
                                                                    <h5 style={{ textAlign: 'right' }}>$400</h5>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan="2">
                                                                    <h4>Terms & Conditions</h4>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan="2">
                                                                    <p>1. Your reservation will be subject to Parking hourly and daily rate as soon as the voucher is expired. The hourly rate is $10/hour and the daily rate is $15 for small cars and $18 for SUV/Trucks per day.</p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan="2">
                                                                    <p>2. This facility does NOT allow in/out privileges. You CANNOT enter & exit more than once.</p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan="2">
                                                                    <p>3. For all Cancelled online vouchers/Reservation customers are required to pay for one day of parking and a $10 service fee.</p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan="2">
                                                                    <p>4. This facility does not allow online reservation extensions. Additional time must be paid on-site at a regular rate.</p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan="2">
                                                                    <p>5. Customer is required to take pictures of their vehicles (all sides) at the location during drop-off and also agree that no damage claim can be filed without providing those pictures.</p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan="2">
                                                                    <p>6. Customer must leave the car key to the attendant and agree that failure to do so may result in towing fees from $75 to $150.</p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan="2">
                                                                    <p>7. Customer agrees that all balance must be paid in full prior to retrieval of vehicle.</p>
                                                                </td>
                                                            </tr>
                                                            <tr className="Signature">
                                                                <td style={{ verticalAlign: 'middle', width: '10%' }}>
                                                                    <div className="check-img">
                                                                        <img src="images/check.png" alt="Check" />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <h4>I accept the Terms & Conditions</h4>
                                                                </td>
                                                            </tr>
                                                            <tr className="Signature">
                                                                <td></td>
                                                                <td>
                                                                    <h4>Customer's Signature</h4>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td>...................................................</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                        </table>
                                </td>
                                <td className="cmn-width">
                                    <table className="common-table">
                                        <tbody>
                                            <tr>
                                                <td style={{ padding: 0 }}>
                                                    <div className="bg-black">
                                                        <h5>Prime Park JFK</h5>
                                                        <p>+918298738900</p>
                                                        <p>5115 Lefferts Blvd South Ozone Park NY 11420</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table className="body-top">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <div className="slot">
                                                                        <p>Slot</p>
                                                                        <h2>09 Sept 2024 | 10:45</h2>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="slot">
                                                                        <p>Pickup: Date</p>
                                                                        <h2>08/31/2024</h2>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div className="check-in">
                                                                        <p>Name</p>
                                                                        <h3>Indranil Sen</h3>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="check-in">
                                                                        <p>Phone number</p>
                                                                        <h3>6291332961</h3>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan="2" style={{ textAlign: 'center' }}>
                                                                    <div className="slot bg-white">
                                                                        <p>Reservation ID</p>
                                                                        <h1>RE2344566</h1>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <table className="bottom-table">
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan="2">
                                                                    <h4>Terms & Conditions</h4>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan="2">
                                                                    <p>1. Your reservation will be subject to Parking hourly and daily rate as soon as the voucher is expired. The hourly rate is $10/hour and the daily rate is $15 for small cars and $18 for SUV/Trucks per day.</p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan="2">
                                                                    <p>2. This facility does NOT allow in/out privileges. You CANNOT enter & exit more than once.</p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan="2">
                                                                    <p>3. For all Cancelled online vouchers/Reservation customers are required to pay for one day of parking and a $10 service fee.</p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan="2">
                                                                    <p>4. This facility does not allow online reservation extensions. Additional time must be paid on-site at a regular rate.</p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan="2">
                                                                    <p>5. Customer is required to take pictures of their vehicles (all sides) at the location during drop-off and also agree that no damage claim can be filed without providing those pictures.</p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan="2">
                                                                    <p>6. Customer must leave the car key to the attendant and agree that failure to do so may result in towing fees from $75 to $150.</p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan="2">
                                                                    <p>7. Customer agrees that all balance must be paid in full prior to retrieval of vehicle.</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default BookingInvoice;

                                           