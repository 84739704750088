import React, { useState, useEffect } from 'react';
import Header from '../../component/Header';
import Sidebar from '../../component/SideBar';
import 'react-quill/dist/quill.snow.css'; 
import ReactQuill from 'react-quill'; 
import Apiconnection from "../../utils/Apiconnection";
import Loader from "../../utils/Loader";
import { Button } from '@mui/material';

const Policies = () => {
    const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
    const [editorHtml, setEditorHtml] = useState('');
    const [loading, setLoading] = useState(false);
    const [policies, setPolicies] = useState('');
    const [isEditorOpen, setIsEditorOpen] = useState(false);

    const OpenSidebar = () => {
        setOpenSidebarToggle(!openSidebarToggle);
    };

    const handleEditorChange = (value) => {
        setEditorHtml(value);
    };

    const fetchPolicies = async () => {
        setLoading(true);
        try {
            const response = await Apiconnection.post('get-policies');
            if (response?.data?.status) {
                const fetchedPolicies = response?.data?.data?.policies || '';
                setPolicies(fetchedPolicies);
                setEditorHtml(fetchedPolicies); 
            }
        } catch (error) {
            console.error('Error fetching policies:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPolicies();
    }, []);

    const handleEditClick = () => {
        setIsEditorOpen(true);
    };

    const handleSaveClick = async () => {
        setLoading(true);
        try {
            const response = await Apiconnection.post('policies-add-update', { content: editorHtml });
            if (response?.data?.status) {
                setPolicies(editorHtml); 
                setIsEditorOpen(false);
            } else {
                console.error('Failed to save policies:', response?.data?.message || 'Unknown error');
            }
        } catch (error) {
            console.error('Error saving policies:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='grid-container'>
            <Header OpenSidebar={OpenSidebar} />
            {loading && <Loader />}
            <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />
            <div className='inner-container'>
                <div className="policy-sec">
                    <div className="top-section">
                        <h3>Modify Policy</h3>
                        <div className='action-btn-sec'>
                            <Button className="action-btn" onClick={handleEditClick}>
                                <i className="fa-solid fa-pen-to-square"></i> Edit
                            </Button>
                            {isEditorOpen && (
                                <Button className="action-btn" onClick={handleSaveClick}>
                                    Save
                                </Button>
                            )}
                        </div>
                    </div>
                    {isEditorOpen ? (
                        <ReactQuill
                            theme="snow"
                            value={editorHtml}
                            onChange={handleEditorChange}
                            modules={Policies.modules}
                            formats={Policies.formats}
                        />
                    ) : (
                        <div dangerouslySetInnerHTML={{ __html: policies }}></div>
                    )}
                </div>
            </div>
        </div>
    );
};

Policies.modules = {
    toolbar: [
        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['bold', 'italic', 'underline'],
        [{ 'align': [] }],
        ['link', 'image'],
        ['clean']
    ],
};

Policies.formats = [
    'header', 'font', 'list', 'bullet',
    'bold', 'italic', 'underline', 'align',
    'link', 'image'
];

export default Policies;
