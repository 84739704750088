import React, { useState, useEffect } from "react";
import Header from '../../component/Header';
import AddIcon from '@mui/icons-material/Add';
import { Button, Modal, Box, FormControl, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import Apiconnection from "../../utils/Apiconnection";
import Loader from "../../utils/Loader";
import { toast } from "react-toastify";
import Sidebar from "../../component/SideBar";


const RateChart = () => {
  const [data, setData] = useState({
    vehicleType: '',
    dailyParkingRate: '',
    overSizeRate: '',
    lateFees: '',
  });
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState('');
  const [rateChartData, setRateChartData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [addedData, setAddedData] = useState({});
  const [editedData, setEditedData] = useState({});
  const [errors, setErrors] = useState({});


  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };


  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleOpenEditModal = () => setOpenEditModal(true);
  const handleCloseEditModal = () => setOpenEditModal(false);


  const handleInputChange = (event) => {
    setAddedData({ ...addedData, [event.target.name]: event.target.value });
  };

  const handleEditInputChange = (event) => {
    setEditedData({ ...editedData, [event.target.name]: event.target.value });
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    await addVehicleData();
    handleCloseModal();
  };

  const editVehicleData = async (payload) => {
    setLoading(true);
    try {
      const response = await Apiconnection.post('price-chart-add-update', payload);
      if (response?.data?.status) {
        await getPriceChartApi();
        toast.success('Updated successfully!');
        handleCloseEditModal();
      } else {
        toast.error(response?.data?.message || 'An error occurred while updating the vehicle data.');
    }
    } catch (error) {
      console.error('Error adding vehicle data:', error);
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    if (editedData?.vehicleType == " ") {
      console.log("vehicleType is required");
    }
    if (editedData?.dailyParkingRate == " ") {
      console.log("dailyParkingRate is required");
    }
    if (editedData?.overSizeRate === undefined || editedData?.overSizeRate === null || editedData?.overSizeRate === "") {
      toast.error("Oversized Vehicle Rate is required.");
      return;
  }
    else if (editedData?.lateFees == " ") {
      console.log("lateFees is required");
    }
    else {
      let payload = {
        "vehicleTypeId": editedData?.vehicleTypeId,
        "vehicleType": editedData?.vehicleType,
        "dailyParkingRate": editedData?.dailyParkingRate,
        "overSizeRate": String(editedData?.overSizeRate),
        "lateFees": editedData?.lateFees,
        "id": editedData?.id,
      };
      console.log('payload', payload);
      await editVehicleData(payload);
      // handleCloseEditModal();
    }
  };



  const addVehicleData = async () => {


    let payload = {
      typeName: addedData?.vehicleType,
      dailyParkingRate: addedData?.dailyParkingRate,
      overSizeRate: addedData?.overSizeRate,
      lateFees: addedData?.lateFees,
    };


    setLoading(true);
    try {
      const response = await Apiconnection.post('vehicle-type-add-update', payload);
      if (response?.data?.status) {
        await getPriceChartApi();
        toast.success('Added successfully!');
        resetAddedData();
    } else {
      toast.error('Vehicle type with same name already exists..!!');
    }
      
    } catch (error) {
      console.error('Error adding vehicle data:', error);
      toast.error('Vehicle type with same name already exists..!!');
    } finally {
      setLoading(false);
    }
  };

  const resetAddedData = () => {
    setAddedData({
      vehicleType: '',
      dailyParkingRate: '',
      overSizeRate: '',
      lateFees: '',
    });
  };

  const getPriceChartApi = async () => {
    setLoading(true);
    try {
      const response = await Apiconnection.post('price-chart-list');
      if (response?.data?.status) {
        console.log('response?.data?.data', response?.data?.data);
        setRateChartData(response?.data?.data);
      }
    } catch (error) {
      console.error('Error fetching price chart data:', error);
    } finally {
      setLoading(false);
    }
  };

  const getUpdatePrice = async (payload) => {
    setLoading(true);
    try {
      const response = await Apiconnection.post('price-chart-add-update', payload);
      if (response?.data?.status) {
        setRateChartData(response?.data?.data);
      }
    } catch (error) {
      console.error('Error updating price:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = (row) => {
    setEditedData(row);
    handleOpenEditModal();
  };


  const handleDeleteClick = async (row) => {
    setLoading(true);
    try {
      const response = await Apiconnection.post('price-chart-delete', { id: row.id });
      if (response?.data?.status) {
        const updatedRateChartData = rateChartData.filter((item) => item.id !== row.id);
        setRateChartData(updatedRateChartData);
        toast.success('Deleted successfully!');
      }
    } catch (error) {
      console.error('Error deleting price chart data:', error);
      toast.error('An error occurred while deleting the price chart data.');
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    getPriceChartApi();
  }, []);

  return (
    <div className='grid-container'>
      {loading && <Loader />}
      <Header OpenSidebar={OpenSidebar} />
      <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar}/>
      <div className='inner-container'>
        <div className='top-section'>
          <h3>Rates</h3>
          <Button variant="contained" className="action-btn" onClick={handleOpenModal}>
            <AddIcon />
            Add New
          </Button>
        </div>
        <TableContainer component={Paper} style={{ marginTop: 20 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Vehicle</TableCell>
                <TableCell>Daily Parking Rate</TableCell>
                <TableCell>Oversized Vehicle Rate</TableCell>
                <TableCell>Late Fees (Same as Daily rate)</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rateChartData && rateChartData?.map((row, index) => (
                <TableRow key={index + 1}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{row.vehicleType?.typeName}</TableCell>
                  <TableCell>{row.dailyParkingRate}</TableCell>
                  <TableCell>{row.overSizeRate}</TableCell>
                  <TableCell>{row.lateFees}</TableCell>
                  <TableCell>
                    <div className="flx-box">
                      <button className="btn btn-sm btn-outline-warning me-2" onClick={() => handleEditClick(row)}>
                        <i className="fa-solid fa-pen-to-square"></i>
                      </button>
                      <button className='btn btn-sm btn-outline-danger' onClick={() => handleDeleteClick(row)}><i className="fa-solid fa-trash"></i></button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="add-new-rate-modal"
          aria-describedby="add-new-rate-modal-description"
          className="rate-modal"
        >
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
            }}
          >
            <h2 id="modal-title">Add New Vehicle Type</h2>
            <div className="mdl-inner">
              <FormControl fullWidth margin="normal">
                <TextField
                  id="vehicle-type"
                  label="Vehicle Type"
                  name="vehicleType"
                  value={addedData.vehicleType}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <TextField
                  id="daily-parking-rate"
                  label="Daily Parking Rate"
                  name="dailyParkingRate"
                  inputProps={{ min: 0, type: 'number' }}
                  value={addedData.dailyParkingRate}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <TextField
                  id="oversized-vehicle-rate"
                  label="Oversized Vehicle Rate"
                  name="overSizeRate"
                  inputProps={{ min: 0, type: 'number' }}
                  value={addedData.overSizeRate}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <TextField
                  id="late-fees"
                  label="Late Fees (Same as Daily rate)"
                  name="lateFees"
                  inputProps={{ min: 0, type: 'number' }}
                  value={addedData.lateFees}
                  onChange={handleInputChange}
                />
              </FormControl>

              <Button variant="contained" type="submit" className="yes-btn" sx={{ mt: 2 }}>
                Add Vehicle Type
              </Button>
            </div>
          </Box>
        </Modal>

        <Modal
          open={openEditModal}
          onClose={handleCloseEditModal}
          aria-labelledby="edit-rate-modal"
          aria-describedby="edit-rate-modal-description"
          className="rate-modal"
        >
          <Box
            component="form"
            onSubmit={handleEditSubmit}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
            }}
          >
            <h2 id="modal-title">Edit Rate</h2>
            <div className="mdl-inner">
            <FormControl fullWidth margin="normal">
                <TextField
                  id="vehicle-type"
                  label="Vehicle Type"
                  name="vehicleType"
                  value={editedData.vehicleType?.typeName}
                  onChange={handleEditInputChange}
                  required
                  disabled
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <TextField
                  id="daily-parking-rate"
                  label="Daily Parking Rate"
                  name="dailyParkingRate"
                  inputProps={{ min: 0, type: 'number' }}
                  value={editedData.dailyParkingRate}
                  onChange={handleEditInputChange}
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <TextField
                  id="oversized-vehicle-rate"
                  label="Oversized Vehicle Rate"
                  name="overSizeRate"
                  inputProps={{ min: 0, type: 'number' }}
                  value={editedData.overSizeRate}
                  onChange={handleEditInputChange}
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <TextField
                  id="late-fees"
                  label="Late Fees (Same as Daily rate)"
                  name="lateFees"
                  inputProps={{ min: 0, type: 'number' }}
                  value={editedData.lateFees}
                  onChange={handleEditInputChange}
                />
              </FormControl>

              <Button variant="contained" type="submit" sx={{ mt: 2 }}>
                Update Rate
              </Button>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default RateChart;
