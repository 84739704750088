import { Outlet, Navigate } from 'react-router-dom'


const Privateroute = () => {

    const token = localStorage.getItem('prime_access_token');


  return (
    token  ? <Navigate  to="/dashboard"/> : <Outlet/>
  )
}

export default Privateroute