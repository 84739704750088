import React, { useState } from "react";
import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/js/custom.js";
import { BrowserRouter as Router, Routes, Route, HashRouter } from "react-router-dom";
import Login from "./views/Login/Login";
import DashboardPage from "./views/dashboard/DashboardPage.js";
import UserList from "./views/dashboard/UserList.js";
import Payments from "./views/dashboard/Payment.js";
import ParkingSlot from "./views/dashboard/ParkingSlots.js";
import RateChart from "./views/dashboard/RateChart.js";
import Setting from "./views/dashboard/Setting.js";
import Vendors from "./views/dashboard/VendorList";
import NotificationsList from "./views/dashboard/NotificationsList.js";
import BookingList from "./views/Booking/BookingList.js";
import AddNewBooking from "./views/Booking/AddNewBooking.js";
import CustomerBooking from "./views/Booking/CustomerBooking.js";
import UseAxiosInterceptors from "./UseAxiosInterceptors.js";
import BookingDetails from "./views/Booking/BookingDetails.js";
import BookingReceipts from "./views/Booking/BookingReceipts.js";
import Privateroute from "./utils/Privateroute.js";
import Publicroute from "./utils/Publicroute.js";
import Sidebar from "./component/SideBar.js";
import Policies from "./views/dashboard/Policies.js";
import AgentList from "./views/dashboard/AgentList";
import BookingInvoice from "./views/Booking/BookingInvoice";

function App() {
  const token = localStorage.getItem('prime_access_token');
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false)

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle)
  }


  return (
    <HashRouter>

      {/* {token != '' &&
        <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />
      } */}

      
    
        <Routes>
        <Route element={<Privateroute />}> 
            <Route path= "/" element={<Login />} />
            <Route path= "/login" element={<Login />} />
        </Route>
        <Route element={<Publicroute />}> 
        <Route path="/dashboard" element={<DashboardPage />} />
              
              <Route path= "/users" element={<UserList />} />
              <Route path="/bookings" element={<BookingList />} />
              <Route path="/parking-slots" element={<ParkingSlot />} />
              <Route path="/rate-chart" element={<RateChart />} />
              <Route path="/payments" element={<Payments />} />
              <Route path="/policies" element={<Policies />} />
              <Route path="/reports" element={<Setting />} />
              <Route path="/vendors" element={<Vendors />} />
              <Route path="/agents" element={<AgentList />} />
              <Route path="/notifications" element={<NotificationsList />} />
              <Route path="/car-movements" element={<Setting />} />
              <Route path="/revenue-report" element={<Setting />} />
              <Route path="/add-booking" element={<AddNewBooking />} />
          <Route path="/customer-booking" element={<CustomerBooking />} />
          <Route path="/booking-details" element={<BookingDetails />} />
          <Route path="/booking-invoice" element={<BookingInvoice />} />
          <Route path="/booking-receipts" element={<BookingReceipts />} />
        </Route>
        
      

        </Routes>

    </HashRouter>
  );
}
 
export default App;