const url = window.location.origin
const asseturl = window.location.origin + '/park-prime/park-prime-frontend-admin'

export const image = {
    logo: asseturl + '/images/logo.png',
    loginbg: asseturl + '/images/login-bg.png',
    usericn: asseturl + '/images/user-icn.png',
    passicn: asseturl + '/images/passwrd-icn.png',
    dashboard: asseturl + '/images/dashboard.png',
    booking: asseturl + '/images/booking.png',
    payment: asseturl + '/images/payment.png',
    parkingslot: asseturl + '/images/parking-slot.png',
    ratechart: asseturl + '/images/rate-chart.png',
    setting: asseturl + '/images/setting.png',
    notification: asseturl + '/images/notification.png',
    logout: asseturl + '/images/logout.png',
    notificationbell: asseturl + '/images/notification-icn.png',
    userimage: asseturl + '/images/user-image.png',
    chartimg1: asseturl + '/images/chart1.png',
    chartimg2: asseturl + '/images/chart2.png',
    chartimg3: asseturl + '/images/chart3.png',
    action: asseturl + '/images/action.png',
    payments: asseturl + '/images/payments.png',
    reports: asseturl + '/images/reports.png',
    vendor: asseturl + '/images/vendor.png',
    policy: asseturl + '/images/policy.png',
    prime_users: asseturl + '/images/prime_users.png',
    car: asseturl + '/images/car.png',
    truck: asseturl + '/images/truck.png',
    edit: asseturl + '/images/edit.png',
    receipts: asseturl + '/images/receipts.png',
    } 