import React, { useState, useEffect } from "react";
import Header from '../../component/Header';
import Sidebar from '../../component/SideBar';
import { image } from "../../common/Theme";
import { Button, Modal, Box, FormControl, TextField, TableBody, InputLabel, FormHelperText, MenuItem, Select, TableCell, TableContainer, TableHead, TableRow, Paper, Switch, FormControlLabel, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { columns, countryCodes, genderJson, staticData, updateFields } from "./utils";
import { useSelector } from "react-redux";
import Table from "../../component/Table";
import Apiconnection from "../../utils/Apiconnection";
import TableLoader from "../../utils/TableLoader";
import Loader from "../../utils/Loader";
import { toast } from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';


const VendorList = () => {

    const [tableloading, settableloading] = useState(false)
    const [editData, setEditData] = useState(null);
    const [userList, setUserList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [userImageUrl, setUserImageUrl] = useState(null);
    const [userImageLink, setUserImageLink] = useState('http://3.108.121.124:4041/uploads/userImages/');
    const [openSidebarToggle, setOpenSidebarToggle] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [errors, setErrors] = useState({})
    const { user } = useSelector((state) => state?.user);
    const [fullname, setfullname] = useState('')
    const [initials, setinitials] = useState('')
    const [onsitechargeable, setonsitechargeable] = useState('')
    const [email, setemail] = useState('')
    const [password, setpassword] = useState('')
    const [dialCode, setdialCode] = useState('+1')
    const [phone, setphone] = useState('')
    const [address, setaddress] = useState('')
    const [addedData, setAddedData] = useState({});
    const [openEditModal, setOpenEditModal] = useState(false);
    const [rateChartData, setRateChartData] = useState([]);
    const [editedData, setEditedData] = useState({});
    const [isOnsiteChargeApplicable, setIsOnsiteChargeApplicable] = useState(false);


    
    const resetForm = () => {
        setfullname('');
        setinitials('');
        setemail('');
        setpassword('');
        setdialCode('+1');
        setphone('');
        setaddress('');
        setIsOnsiteChargeApplicable(false);
    };

    const [validationErrors, setValidationErrors] = useState({
        fullname: "",
        initials: "",
        email: "",
        password: "",
        dialCode: "",
        phone: "",
        address: "",
    });

    const validateForm = () => {
        const errors = {
            fullname: "",
            initials: "",
            email: "",
            password: "",
            dialCode: "",
            phone: "",
            address: "",
        };

        if (!fullname) {
            errors.fullname = "Name is required";
        }

        if (!initials) {
            errors.initials = "Initials is required";
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email || !emailRegex.test(email)) {
            errors.email = "Valid email is required";
        }

        if (!password) {
            errors.password = "Password is required";
        }

        if (!dialCode) {
            errors.dialCode = "DialCode is required";
        }

        if (!phone || phone.length < 10) {
            errors.phone = "Valid phone number is required";
        }

        if (!address) {
            errors.address = "Address is required";
        }

        setValidationErrors(errors);

        return Object.values(errors).every((error) => !error);
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        if (validateForm()) {
            await addVendorData();
            handleCloseModal();
        }
    };

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    const handleOpenEditModal = () => setOpenEditModal(true);
    const handleCloseEditModal = () => setOpenEditModal(false);

    const handleSidebarToggle = () => {
        setOpenSidebarToggle(!openSidebarToggle);
    };

    const [formData, setFormData] = useState({
        id: '',
        firstname: '',
        initials: '',
        email: '',
        password: '',
        dialCode: '',
        phone: '',
        address: '',
        onsitechargeable: '',
        userImageUrl: null,
    });

    const handleToggleOnsiteCharge = (event) => {
        setIsOnsiteChargeApplicable(event.target.checked);
    };

    const handleEditInputChange = (event) => {
        setEditedData({ ...editedData, [event.target.name]: event.target.value });
    };

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await Apiconnection.post('vendor-details/', { id: editedData });
                console.log(response)
                if (response.data.status) {
                    const userData = response.data.data;
                    setFormData({
                        id: userData.user.id,
                        firstname: userData.user.firstName,
                        initials: userData.initials,
                        email: userData.user.email,
                        password: '',
                        dialCode: userData.user.dialCode,
                        phone: userData.user.phone,
                        address: userData.user.address,
                        onsitechargeable: userData.isOnsiteChargeApplicable,
                        userImageUrl: userData.user.profileImage,
                    });
                    setUserImageLink(userData.userImageLink);
                    setIsOnsiteChargeApplicable(userData.isOnsiteChargeApplicable);
                } else {
                    // toast.error(response.data.message);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
                // toast.error('Failed to fetch user data.');
            }
        };

        fetchUserData();
    }, [editedData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleFirstnameChange = (e) => {
        setFormData({ ...formData, firstname: e.target.value });
    };

    const handleInitialsChange = (e) => {
        setFormData({ ...formData, initials: e.target.value });
    };

    const handleEmailChange = (e) => {
        setFormData({ ...formData, email: e.target.value });
    };

    const handlePasswordChange = (e) => {
        setFormData({ ...formData, password: e.target.value });
    };

    const handleDialCodeChange = (e) => {
        if (e.target.value === 'default') {
            setdialCode('+1');
        } else {
            setdialCode(e.target.value);
        }
    };

    const handlePhoneChange = (e) => {
        setFormData({ ...formData, phone: e.target.value });
    };

    const handleAddressChange = (e) => {
        setFormData({ ...formData, address: e.target.value });
    };

    const handleOnsiteChargeableChange = (e) => {
        setFormData({ ...formData, onsitechargeable: e.target.value });
    };

    const handleUserImageUrlChange = (e) => {
        setFormData({ ...formData, userImageUrl: e.target.files[0] });
    };

    const handleEditedDataChange = (key, value) => {
        setEditedData({ ...editedData, [key]: value });
    };


    const handleImgChange = (e) => {
        const { name, files } = e.target;
        if (name === 'userImageUrl') {
            setFormData((prevData) => ({
                ...prevData,
                userImageUrl: files[0],
            }));
        }
    };

    const editVendorData = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('id', formData.id);
        formDataToSend.append('name', formData.firstname);
        formDataToSend.append('initials', formData.initials);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('password', formData.password);
        formDataToSend.append('dialCode', formData.dialCode);
        formDataToSend.append('phone', formData.phone);
        formDataToSend.append('address', formData.address);
        formDataToSend.append('isOnsiteChargeApplicable', isOnsiteChargeApplicable ? 1 : 0);

        const fullImageUrl = `${userImageLink}${formData.userImageUrl}`;
        formDataToSend.append('profileImage', fullImageUrl);

        setLoading(true);

        try {
            const response = await Apiconnection.post('vendor-add-update', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response?.data?.status) {
                await getVendorListApi();
                toast.success('Updated successfully!');
            } else {
                toast.error(response?.message);
            }
        } catch (error) {
            console.error('Error adding Vendor data:', error);
            // toast.error('Vendor with same name already exists..!!');
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, files } = e.target;
        if (name === 'userImageUrl') {
            setUserImageUrl(files[0]);
        }
    };

    useEffect(() => {
        getVendorListApi();
    }, []);

    const OpenSidebar = () => {
        setOpenSidebarToggle(!openSidebarToggle)
    }

    const handleDeleteClick = async (row) => {
        console.log(row);
        setLoading(true);
        try {
            const response = await Apiconnection.post('user-soft-delete', { id: row.user.id });
            if (response?.data?.status) {
                toast.success('Vendor deleted successfully!');
                await getVendorListApi(); 
            } else {
                toast.error(response?.data?.message); 
            }
        } catch (error) {
            console.error('Error deleting vendor:', error);
            
            if (error.response && error.response.data) {
                toast.error(error.response.data.message);
            } else {
                toast.error('An error occurred while deleting the vendor.'); 
            }
        } finally {
            setLoading(false);
        }
    };

    const handleEditClick = (row) => {
        setEditedData(row);
        handleOpenEditModal();
    };



    const addVendorData = async () => {

        const formData = new FormData();
        formData.append('name', fullname);
        formData.append('initials', initials);
        formData.append('email', email);
        formData.append('password', password);
        formData.append('dialCode', dialCode);
        formData.append('phone', phone);
        formData.append('address', address);
        formData.append('profileImage', userImageUrl);
        formData.append('isOnsiteChargeApplicable', isOnsiteChargeApplicable ? 1 : 0);

        setLoading(true);
        try {
            const response = await Apiconnection.post('vendor-add-update', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response?.data?.status) {
                await getVendorListApi();
                toast.success('Added successfully!');
                resetForm();
            } else {
                toast.error(response?.message);
            }
        } catch (error) {
            console.error('Error adding Vendor data:', error);
            toast.error('Vendor with same name already exists..!!');
        } finally {
            setLoading(false);
        }
    };

    const handleeditSubmit = async (event) => {
        event.preventDefault();
        // if (validateForm()) {
        //     await editVendorData();
        //     handleCloseEditModal();
        // }
        let payload = {
            "id": editedData?.id,
            "name": formData?.name,
            "email": formData?.email,
            "password": formData?.password,
            "dialCode": formData?.dialCode,
            "phone": formData?.phone,
            "address": formData?.address,
            "profileImage": formData?.userImageUrl,
            "isOnsiteChargeApplicable": isOnsiteChargeApplicable,
        };
        console.log('payload', payload);
        await editVendorData();
        handleCloseEditModal();
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        await addVendorData();
        handleCloseModal();
    };

    const getVendorListApi = async () => {
        setLoading(true);
        try {
            const response = await Apiconnection.post('vendor-list');
            if (response?.data?.status) {
                console.log('response?.data?.userList', response?.data?.data?.vendorList);
                setUserList(response?.data?.data?.vendorList);
            }
        } catch (error) {
            console.error('Error fetching price chart data:', error);
        } finally {
            setLoading(false);
        }
    };


    return (
        <div className='grid-container'>
            <Header OpenSidebar={OpenSidebar} />
            <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar}/>
            <div className='user-list'>
                <div className='top-section'>
                    <h3>Vendor List</h3>
                    <Button variant="contained" className="action-btn" onClick={handleOpenModal}>
                        <AddIcon />
                        Add Vendor
                    </Button>
                </div>

                <div className='custom-table table-responsive'>
                    {!tableloading ?

                        <table className='table '>
                            <thead>
                                <th>Name</th>
                                {/* <th>Image</th> */}
                                <th>Initials</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Address</th>
                                <th>Action</th>
                            </thead>
                            <tbody>
                                {userList && userList.length > 0 ?
                                    userList && userList.map((list, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{list.user.firstName + ' ' + list.user.lastName}</td>
                                                <td>{list.initials}</td>
                                                <td>{list.user.email}</td>
                                                <td>{list.user.phone}</td>
                                                <td>{list.user.address}</td>
                                                <td>
                                                    <div className="flx-box">
                                                        <button className="btn btn-sm btn-outline-warning me-2" onClick={() => handleEditClick(list?.id)}>
                                                            <i className="fa-solid fa-pen-to-square"></i>
                                                        </button>
                                                        <button className='btn btn-sm btn-outline-danger' onClick={() => handleDeleteClick(list)}>
                                                            <i className="fa-solid fa-trash"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr>
                                        <td colSpan="8" className='text-center'>No data record</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                        :
                        <TableLoader />
                    }
                </div>

                <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="add-user-modal"
                    aria-describedby="add-user-modal-description"
                >
                    <div className="right-open-modal">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h2 id="modal-title">Add Vendor</h2>
                        <IconButton onClick={handleCloseModal} aria-label="close" style={{marginBottom: '15px'}}>
                            <CloseIcon />
                        </IconButton>
                        </div>
                        <form onSubmit={handleFormSubmit}>
                            <TextField
                                id="fullname"
                                label="Name"
                                className="mt-3"
                                value={fullname}
                                onChange={(e) => setfullname(e.target.value)}
                                name="fullname"
                                error={!!validationErrors.fullname}
                                helperText={validationErrors.fullname}
                            />
                            <TextField
                                id="initials"
                                label="Initials"
                                value={initials}
                                name="initials"
                                onChange={(e) => setinitials(e.target.value)}
                                error={!!validationErrors.initials}
                                helperText={validationErrors.initials}
                            />
                            <TextField
                                id="email"
                                label="Email"
                                name="email"
                                value={email}
                                onChange={(e) => setemail(e.target.value)}
                                error={!!validationErrors.email}
                                helperText={validationErrors.email}
                            />
                            <TextField
                                id="password"
                                label="Password"
                                name="password"
                                value={password}
                                onChange={(e) => setpassword(e.target.value)}
                                type="password"
                                error={!!validationErrors.password}
                                helperText={validationErrors.password}
                            />
                            <InputLabel>Dialcode</InputLabel>
                            <Select
                                label="Dialcode"
                                id="dialCode"
                                value={dialCode}
                                onChange={handleDialCodeChange}
                                name="dialCode"
                            >
                                {/* <MenuItem value="default">+1</MenuItem> */}
                                {countryCodes.map((countryCode) => (
                                    <MenuItem key={countryCode.value} value={countryCode.value}>
                                        {countryCode.value}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{errors?.dialCode}</FormHelperText>
                            <TextField
                                id="phone"
                                label="Mobile Number"
                                name="phone"
                                value={phone}
                                inputProps={{ maxLength: 10 }}
                                onChange={(e) => setphone(e.target.value)}
                                error={!!validationErrors.phone}
                                helperText={validationErrors.phone}
                            />
                            <TextField
                                id="address"
                                label="Address"
                                name="address"
                                value={address}
                                onChange={(e) => setaddress(e.target.value)}
                            />
                            <FormControl component="fieldset" style={{ display: 'flex', alignItems: 'center', marginTop: '16px' }}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={isOnsiteChargeApplicable}
                                            onChange={handleToggleOnsiteCharge}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    }
                                    label="Onsite Chargeable"
                                    labelPlacement="end" // Ensures label is on the right of the switch
                                    style={{ margin: 0 }} // Prevents margin from affecting alignment
                                />
                            </FormControl>
                            {/* <input
                                id="userImageUrl"
                                type="file"
                                onChange={handleChange}
                                name="userImageUrl"
                                style={{ marginBottom: '20px' }}
                            /> */}
                            <Button type="submit" variant="contained" color="primary">
                                Submit
                            </Button>
                        </form>
                    </div>
                </Modal>

                <Modal
                    open={openEditModal}
                    onClose={handleCloseEditModal}
                    aria-labelledby="add-user-modal"
                    aria-describedby="add-user-modal-description"
                >
                    <div className="right-open-modal">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h2 className="mb-3" id="modal-title">Edit Vendor</h2>
                        <IconButton onClick={handleCloseEditModal} aria-label="close" style={{marginBottom: '15px'}}>
                            <CloseIcon />
                        </IconButton>
                        </div>
                        <form onSubmit={handleeditSubmit}>
                            <TextField
                                id="fullname"
                                label="Name"
                                value={formData.firstname}
                                onChange={handleFirstnameChange}
                                name="fullname"
                                error={!!validationErrors.fullname}
                                helperText={validationErrors.fullname}
                            />
                            <TextField
                                id="initials"
                                label="Initials"
                                value={formData.initials}
                                name="initials"
                                onChange={handleInitialsChange}
                                error={!!validationErrors.initials}
                                helperText={validationErrors.initials}
                            />
                            <TextField
                                id="email"
                                label="Email"
                                name="email"
                                value={formData.email}
                                onChange={handleEmailChange}
                                error={!!validationErrors.email}
                                helperText={validationErrors.email}
                            />
                            <TextField
                                id="password"
                                label="Password"
                                name="password"
                                value={formData.password}
                                onChange={handlePasswordChange}
                                type="password"
                            />
                            <InputLabel>Dialcode</InputLabel>
                            <Select
                                label="Dialcode"
                                id="dialCode"
                                value={formData.dialCode}
                                onChange={handleDialCodeChange}
                                name="dialCode"
                            >
                                {countryCodes.map((countryCode) => (
                                    <MenuItem key={countryCode.value} value={countryCode.value}>
                                        {countryCode.value}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{errors?.dialCode}</FormHelperText>
                            <TextField
                                id="phone"
                                label="Mobile Number"
                                name="phone"
                                inputProps={{ maxLength: 10 }}
                                value={formData.phone}
                                onChange={handlePhoneChange}
                                error={!!validationErrors.phone}
                                helperText={validationErrors.phone}
                            />
                            <TextField
                                id="address"
                                label="Address"
                                name="address"
                                value={formData.address}
                                onChange={handleAddressChange}
                            />
                            <FormControl component="fieldset" style={{ display: 'flex', alignItems: 'center', marginTop: '16px' }}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={isOnsiteChargeApplicable}
                                            onChange={handleToggleOnsiteCharge}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    }
                                    label="Onsite Chargeable"
                                    labelPlacement="end" // Ensures label is on the right of the switch
                                    style={{ margin: 0 }} // Prevents margin from affecting alignment
                                />
                            </FormControl>
                            {/* <input
                                id="userImageUrl"
                                type="file"
                                onChange={handleChange}
                                name="userImageUrl"
                                style={{ marginBottom: '20px' }}
                            /> */}
                            <Button type="submit" variant="contained" color="primary"> Submit </Button>
                        </form>
                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default VendorList;