import React, { useEffect, useState } from 'react';
import Header from '../../component/Header';
import Sidebar from '../../component/SideBar';
import { Grid, Box, TextField, Typography, Button, FormControl, InputLabel, Select, MenuItem, IconButton, ListItemIcon, ListItemText, Input, FormControlLabel, Radio, RadioGroup, FormLabel } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { image } from "../../../src/common/Theme";
import FormControlContext from '@mui/material/FormControl/FormControlContext';
import { useLocation, useNavigate } from 'react-router-dom';
import Apiconnection from '../../utils/Apiconnection';
import { toast } from 'react-toastify';
import moment from 'moment/moment';

const BookingDetails = () => {

  const location = useLocation();
  const payload = location.state;
  console.log('bookingDetails', payload)


  const [customerName, setCustomerName] = useState(payload?.firstName + ' ' + payload?.lastName);
  const [Firstname, setFirstname] = useState(payload?.firstName);
  const [Lastname, setLastname] = useState(payload?.lastName);
  const [vendorId, setVendorId] = useState(payload?.vendorId);
  const [email, setEmail] = useState(payload?.email);
  const [vechiletypeId, setvechiletypeId] = useState(payload?.vehicleTypeId);
  const [ReservationId, setReservationId] = useState(payload?.reservationId);
  const [cnfReservationId, setcnfReservationId] = useState(payload?.reservationId);
  const [bookingPlatform, setbookingPlatform] = useState(payload?.bookingPlatform);
  const [logInType, setlogInType] = useState(payload?.logInType);
  const [slotId, setslotId] = useState(payload?.slotId);
  const [dialCode, setdialCode] = useState(payload?.dialCode);
  const [fromDate, setfromDate] = useState(payload?.fromDate);
  const [selectedCheckInTime, setselectedCheckInTime] = useState(payload?.selectedCheckInTime);
  const [toDate, settoDate] = useState(payload?.toDate);
  const [selectedCheckOutTime, setselectedCheckOutTime] = useState(payload?.selectedCheckOutTime);
  const [passengerCount, setpassengerCount] = useState(payload?.passengerCount);
  const [customerId, setcustomerId] = useState(payload?.customerId);
  const [actualvehicleTypeId, setactualvehicleTypeId] = useState(payload?.actualvehicleTypeId);
  const [actualFromDate, setactualFromDate] = useState(payload?.actualFromDate);
  const [actualCheckInTime, setactualCheckInTime] = useState(payload?.actualCheckInTime);
  const [actualToDate, setactualToDate] = useState(payload?.actualToDate);
  const [actualCheckOutTime, setactualCheckOutTime] = useState(payload?.actualCheckOutTime);
  const [dailyParkingRate, setdailyParkingRate] = useState(payload?.dailyParkingRate);
  const [onSiteChargeForVendor, setonSiteChargeForVendor] = useState(payload?.onSiteChargeForVendor);

  const [mobileNumber, setMobileNumber] = useState(payload?.phone);
  const [plateNumber, setPlateNumber] = useState(payload?.plateNumber);
  const [confirmPlateNumber, setConfirmPlateNumber] = useState(payload?.plateNumber);
  const [numPassengers, setNumPassengers] = useState(payload?.passengerCount);
  const [carType, setCarType] = useState(payload?.carType || '');
  const [slotName, setSlotName] = useState(payload?.slotName || '');
  const [startDateTime, setStartDateTime] = useState(payload?.fromDate + 'T' + payload?.selectedCheckInTime);
  const [endDateTime, setEndDateTime] = useState(payload?.toDate + 'T' + payload?.selectedCheckOutTime);
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
  const [invoiceCreate, setInvoiceCreate] = useState({});
  const [bookingRequestDetail, setBookingRequestDetail] = useState({});
  const [vehicleTypeName, setVehicleTypeName] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  const handlePassengerIncrease = () => {
    setNumPassengers(numPassengers + 1);
  };

  const handlePassengerDecrease = () => {
    if (numPassengers > 1) {
      setNumPassengers(numPassengers - 1);
    }
  };



  const handleCarTypeChange = (event) => {
    setCarType(event.target.value);
  };
  const navigate = useNavigate();

  const calculateDays = () => {
    const startDate = new Date(startDateTime);
    const endDate = new Date(endDateTime);
    const totalDiffInMillis = endDate - startDate;
    const totalDays = Math.floor(totalDiffInMillis / (1000 * 3600 * 24));
    const remainingMillis = totalDiffInMillis % (1000 * 3600 * 24);
    const remainingHours = Math.floor(remainingMillis / (1000 * 3600));
    const remainingMinutes = Math.floor((remainingMillis % (1000 * 3600)) / (1000 * 60));
    if (remainingHours > 0 || remainingMinutes > 15) {
      return totalDays + 1;
    } else {
      return totalDays;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const bookingPayload = {
      bookingPlatform: "adminPanel",
      logInType: payload.customerExist ? "Customer" : "Guest",
      slotId: payload.slotId,
      slotName: slotName,
      firstName: payload.firstName,
      lastName: payload.lastName,
      vehicleTypeId: payload.vehicleTypeId,
      email: payload.email,
      dialCode: payload.dialCode,
      phone: payload.phone,
      plateNumber: payload.plateNumber,
      fromDate: moment(startDateTime).format("YYYY-MM-DD"),
      selectedCheckInTime: moment(startDateTime).format("HH:mm"),
      toDate: moment(endDateTime).format("YYYY-MM-DD"),
      selectedCheckOutTime: moment(endDateTime).format("HH:mm"),
      passengerCount: numPassengers,
      customerId: payload.customerId,
      vendorId: payload.vendorId,
      actualvehicleTypeId: payload.vehicleTypeId,
      actualFromDate: moment(startDateTime).format("YYYY-MM-DD"),
      actualCheckInTime: moment(startDateTime).format("HH:mm"),
      actualToDate: moment(endDateTime).format("YYYY-MM-DD"),
      actualCheckOutTime: moment(endDateTime).format("HH:mm"),
      carType: payload.carType,
      dailyParkingRate: payload.dailyParkingRate,
      reservationId: payload.reservationId,
    };

    try {
      const response = await Apiconnection.post('booking-request-create', bookingPayload);
      if (response?.data?.status) {
        const bookingRequestId = response.data.data.id;
        const calculateBookingChargesPayload = {
          bookingRequestId,
          vendorId: payload.vendorId,
          onSiteChargeForVendor: payload.onSiteChargeForVendor,
          vehicleTypeId: payload.vehicleTypeId,
          passengerCount: numPassengers,
          checkinDate: moment(startDateTime).format("YYYY-MM-DD"),
          startTime: moment(startDateTime).format("HH:mm"),
          checkoutDate: moment(endDateTime).format("YYYY-MM-DD"),
          endTime: moment(endDateTime).format("HH:mm"),
          reservationId: payload.reservationId,
        };
        const calculateBookingChargesResponse = await Apiconnection.post('calculate-booking-charges-before-booking', calculateBookingChargesPayload);
        if (calculateBookingChargesResponse?.data?.status) {
          toast.success('Booking created successfully!');
          navigate('/bookings');
        } else {
          toast.error('Failed to calculate booking charges!');
        }
      } else {
        toast.error('Failed to create booking!');
      }
    } catch (error) {
      console.error('Error creating booking:', error);
      toast.error('Failed to create booking!');
    }
  };

  useEffect(() => {
    const bookingRequestId = location.state?.bookingRequestId;
    if (bookingRequestId) {
      getBookingDetails(bookingRequestId);
    }
  }, [location.state]);

  useEffect(() => {
    const getPreBookingChargeSummary = async () => {
      if (payload) {
        try {
          const payloadData = {
            vendorId: payload.vendorId,
            onSiteChargeForVendor: payload.onSiteChargeForVendor,
            vehicleTypeId: payload.vehicleTypeId,
            passengerCount: payload.passengerCount,
            checkinDate: payload.fromDate,
            startTime: payload.selectedCheckInTime,
            checkoutDate: payload.toDate,
            endTime: payload.selectedCheckOutTime,
            reservationId: payload.reservationId
          };
          const response = await Apiconnection.post('pre-booking-charge-summary', payloadData);
          console.log('pre-booking-charge-summary', response);
          if (response && response.data) {
            if (response.data.status) {
              setInvoiceCreate(response.data.data.bookingChargesSummary);
              setCustomerName(payload.firstName + ' ' + payload.lastName);
              setMobileNumber(payload.phone);
              setPlateNumber(payload.plateNumber);
              setConfirmPlateNumber(payload.plateNumber);
              setNumPassengers(payload.passengerCount);
              setCarType(payload.carType);
              setStartDateTime(payload.fromDate + 'T' + payload.selectedCheckInTime);
              setEndDateTime(payload.toDate + 'T' + payload.selectedCheckOutTime);

            } else {
              console.error('Error fetching pre-booking charge summary:', response.data.message);
            }
          } else {
            console.error('Error fetching pre-booking charge summary: No response data');
          }
        } catch (error) {
          console.error('Error fetching pre-booking charge summary:', error);
        }
      } else {
        console.error('Error fetching pre-booking charge summary: Payload is null or undefined');
      }
    };
    getPreBookingChargeSummary();
  }, [payload]);

  const getBookingDetails = async (bookingRequestId) => {
    try {
      const response = await Apiconnection.post('calculate-booking-charges-before-booking', {
        bookingRequestId,
      });
      if (response?.data?.status) {
        setInvoiceCreate(response.data.data.invoiceCreate);
        setBookingRequestDetail(response.data.data.bookingRequestDetail);
      } else {
        console.error('Error fetching booking details:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching booking details:', error);
    }
  };

  return (
    <div className='grid-container'>
      <Header OpenSidebar={OpenSidebar} />
      <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />
      <div className='inner-container'>
        <div className="booking-details-sec">
          <form>
            <Grid container spacing={2}>
              <Grid item lg={8} md={8}>
                <div className='details-box'>
                  <h5 className='top-heading'>Booking Details</h5>

                  <div className='form-box'>
                    <FormControl variant="standard">
                      <InputLabel htmlFor="component-simple">Customer Name</InputLabel>
                      <Input id="component-simple" disabled defaultValue={payload?.firstName + ' ' + payload?.lastName || ''} />
                    </FormControl>
                    <FormControl variant="standard">
                      <InputLabel htmlFor="component-simple">Customer Mobile Number</InputLabel>
                      <Input id="component-simple" disabled defaultValue={payload?.phone || ''} />
                    </FormControl>
                  </div>

                  <div className='form-box'>
                    <FormControl variant="standard">
                      <InputLabel htmlFor="component-simple">Plate Number</InputLabel>
                      <Input id="component-simple" disabled defaultValue={payload?.plateNumber} />
                    </FormControl>
                    <FormControl variant="standard">
                      <InputLabel htmlFor="component-simple">Reservation ID</InputLabel>
                      <Input id="component-simple" disabled defaultValue={payload?.reservationId} />
                    </FormControl>
                  </div>

                  <div className='form-box'>
                    <FormControl variant="standard">
                      <InputLabel htmlFor="component-simple">Car Type</InputLabel>
                      <Input id="component-simple" disabled defaultValue={carType} />
                    </FormControl>
                    <FormControl variant="standard">
                      <InputLabel htmlFor="component-simple">No. Of Passengers</InputLabel>
                      <Input id="component-simple" disabled defaultValue={payload?.passengerCount} />
                    </FormControl>
                  </div>

                  <div className='form-box'>
                    <FormControl variant="standard">
                      <InputLabel htmlFor="check-in">Check In Date & Time</InputLabel>
                      <Input type="datetime-local" disabled id="check-in" defaultValue={payload?.fromDate + 'T' + payload?.selectedCheckInTime || ''} />
                    </FormControl>
                    <FormControl variant="standard">
                      <InputLabel htmlFor="check-out">Check Out Date & Time</InputLabel>
                      <Input type="datetime-local" disabled id="check-out" defaultValue={payload?.toDate + 'T' + payload?.selectedCheckOutTime || ''} />
                    </FormControl>
                  </div>

                  <div className='form-box'>
                    {/* <FormControl variant="standard">
                      <InputLabel htmlFor="component-simple">Ticket ID</InputLabel>
                      <Input id="component-simple" defaultValue={payload?.id} />
                    </FormControl> */}
                    <FormControl variant="standard">
                      <InputLabel htmlFor="component-simple">Slot No.</InputLabel>
                      <Input id="component-simple" disabled defaultValue={slotName} />
                    </FormControl>
                  </div>
                  <div className='payment-mode'>
                    <FormControl>
                      <FormLabel id="demo-radio-buttons-group-label">Payment Mode</FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                      >
                        <FormControlLabel value="cash" control={<Radio />} label="Cash" />
                        <FormControlLabel value="card" control={<Radio />} label="Card" />
                      </RadioGroup>
                    </FormControl>
                  </div>

                </div>
              </Grid>
              <Grid item lg={4} md={4}>
                <div className='billing-details'>
                  <h5 className='top-heading'>Booking Details</h5>
                  {/* <div className='billing-box'>
                    <p>Reservation charges</p>
                    <h6>$ {invoiceCreate?.reservationAmount || 0}</h6>
                  </div> */}
                  <div className='billing-box'>
                    <p>On Site Charge For Vendor</p>
                    <h6>$ {invoiceCreate?.onSiteChargeForVendor || 0}</h6>
                  </div>
                  <div className='billing-box'>
                    <p>
                      Parking Charges
                      <span>
                        ({calculateDays()} days * ${(payload?.vendorId && invoiceCreate?.onSiteChargeForVendor !== 0) ? payload?.oversizeRate : payload?.dailyParkingRate} - {carType})
                      </span>
                    </p>
                    <h6>$ {invoiceCreate?.parkingChargeTotal || 0}</h6>
                  </div>
                  <div className='billing-box'>
                    <p>Overstay charges <span>({invoiceCreate?.overstayDays} days)</span></p>
                    <h6>$ {invoiceCreate?.overstayAmountTotal || 0}</h6>
                  </div>
                  <div className='billing-box'>
                    <p>Extra Passenger Fees <span>({invoiceCreate?.extraPassengers} Passengers)</span></p>
                    <h6>$ {invoiceCreate?.extraPassengerChargesTotal || 0}</h6>
                  </div>
                  <div className='billing-box'>
                    <p>Early Check-In Fee</p>
                    <h6>$ {invoiceCreate?.earlyCheckinAmountTotal || 0}</h6>
                  </div>
                  <div className='total-amount'>
                    <p>Total amount to pay</p>
                    <h6>$ {invoiceCreate?.payableAmount || 0}</h6>
                  </div>
                </div>
                <Box display="flex" justifyContent="center" gap={2}>
                  <Button className='edit-btn' onClick={() => {
                    const updatedPayload = {
                      vendorId: vendorId,
                      email: email,
                      customerName: customerName,
                      firstName: Firstname, 
                      lastName: Lastname, 
                      mobileNumber: mobileNumber,
                      plateNumber: plateNumber,
                      confirmPlateNumber: confirmPlateNumber,
                      carType: carType,
                      reservationId: ReservationId,
                      cnfReservationId: cnfReservationId,
                      vechiletypeId: vechiletypeId,
                      startDateTime: startDateTime,
                      endDateTime: endDateTime,
                      numPassengers: numPassengers,
                      slotName: slotName,
                      bookingPlatform: bookingPlatform,
                      logInType: logInType,
                      slotId: slotId,
                      dialCode: dialCode,
                      fromDate: fromDate,
                      toDate: toDate,
                      onSiteChargeForVendor: onSiteChargeForVendor,

                    };
                    console.log("updatedPayload",updatedPayload)
                    navigate('/add-booking', { state: { updatedPayload } });
                  }}>
                    <img src={image.edit} alt="edit" />
                    Edit
                  </Button>
                  <Button type="submit" className='save-btn' onClick={handleSubmit}>
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BookingDetails;
