import React, { useEffect, useState } from "react";
import Header from '../../component/Header';
import Sidebar from '../../component/SideBar';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Apiconnection from "../../utils/Apiconnection";
import Loader from "../../utils/Loader";

const ParkingSlot = () => {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
  const [value, setValue] = useState("0"); // Default to the first tab


  const [loading, setLoading] = useState(false);
  const [parkingslotData, setparkingslotData] = useState([]);
  const [parkingslotDetails, setparkingslotDetails] = useState([]);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getParkingSlotApi();
  }, []);

  const getParkingSlotApi = async () => {
    setLoading(true);
    try {
      const response = await Apiconnection.post('slot-list');
      if (response?.data?.status) {
        setparkingslotData(response?.data?.data?.parkingGroundDetails);
        //setparkingslotDetails(response?.data?.data?.groupedSlots)
        // const groupedSlots = response?.data?.data?.groupedSlots;
        // const slotDetailsArray = Object.values(groupedSlots).flatMap(group => Object.values(group.rows));
        // setparkingslotDetails(slotDetailsArray);
        // console.log(Object.entries(response?.data?.data?.groupedSlots))
        var TempArr = []

        {
          Object.entries({
            ...response?.data?.data?.groupedSlots,
          }).map(([key, item], index) => {
            var inTempArr = []
            Object.entries({
              ...item.rows,
            }).map(([inkey, innitem]) => {
              inTempArr.push(innitem)
            })
            TempArr.push({
              'slotid': key,
              'nrow': item.noOfRows,
              'ncol': item?.noOfBays,
              'totalcars': item?.noOfRows * item?.noOfBays,
              'lotname': item?.lotName,
              'innrow': inTempArr
            })
          })
        }
        setparkingslotDetails(TempArr)


      }
    } catch (error) {
      console.error('Error fetching price chart data:', error);
    } finally {
      setLoading(false);
    }
  };


  console.log(parkingslotDetails)

  return (
    <div className='grid-container'>
      <Header OpenSidebar={OpenSidebar} />
      <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar}/>
      <div className='inner-container responsive-class'>
        {parkingslotData?.map((parkingGround, index) => (
          <div key={index} className="d-flex" style={{ gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
            <div className="item col-lg-4">
              <h3 style={{ fontSize: '18px', fontWeight: '500' }}>Business Name: <span className="dynamic-item">{parkingGround.parkingBusiness.businessName}</span></h3>
            </div>
            <div className="item col-lg-4">
              <h3 style={{ fontSize: '18px', fontWeight: '500' }}>Acquired: <span className="dynamic-item">{parkingGround.parkingBusiness.isAquired ? 'Yes' : 'No'}</span></h3>
            </div>
            <div className="item col-lg-4">
              <h3 style={{ fontSize: '18px', fontWeight: '500' }}>No. of Grounds: <span className="dynamic-item">{parkingGround.parkingBusiness.noOfGrounds}</span></h3>
            </div>
          </div>
        ))}
        {parkingslotData?.map((parkingGround, index) => (

          <div key={index} className="d-flex" style={{ gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>


            <div className="item col-lg-4 mt-4">
              <h3 style={{ fontSize: '18px', fontWeight: '500' }}>Ground Availability: <span className="dynamic-item">{parkingGround.isGroundFull ? 'Full' : 'Available'}</span></h3>
            </div>
            <div className="item col-lg-8 mt-4">
              <h3 style={{ fontSize: '18px', fontWeight: '500' }}>Ground Name: <span className="dynamic-item">{parkingGround.groundName}</span></h3>
            </div>
          </div>
        ))}
        <hr></hr>
        <div className="dc-wrap dashboard-car-status">
          <div className="dc-header">
            <div className="dch-left">
              <h3 className="dc-head">Parking Lot Diagram</h3>
            </div>
            <div className="dch-right">
              <div className="parking-indicator">
                <div className="ind-box">
                  <span className="ind-color overstay" />
                  <span className="ind-name">Overstay Car</span>
                </div>
                <div className="ind-box">
                  <span className="ind-color leaving" />
                  <span className="ind-name">Leaving Today</span>
                </div>
                <div className="ind-box">
                  <span className="ind-color parked" />
                  <span className="ind-name">Parked Car</span>
                </div>
                <div className="ind-box">
                  <span className="ind-color vacant" />
                  <span className="ind-name">Vacant Slots</span>
                </div>
              </div>
            </div>
          </div>
          <div className="dc-body">
            <div className="lot-diagram-wrap">
              <div className="ld-col col-lg-5 col-12">
                <div className="ld-col-content lot-a">
                  <div className="ldc-header">
                    <div className="lot-detail">
                      {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                        console.log(item)
                        if (item?.slotid == '14') {
                          return (
                            <>
                              <h4>
                                {item?.nrow} <small>Rows</small>x {item?.ncol}<small>Bays</small> [<small>Total:</small>{item?.totalcars} <small>Cars</small>]
                              </h4>
                            </>
                          )
                        }
                      })}
                      {/* <p>For cars parked for 3 weeks or more </p> */}
                    </div>
                    {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                      console.log(item)
                      if (item?.slotid == '14') {
                        return (
                          <div className="lot-name">{item?.lotname}</div>
                        )
                      }
                    })}
                  </div>
                  {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                    if (item?.slotid == '14') {
                      return (
                        <div className="ldc-body" key={index}>
                          {item?.innrow.map((slot, slotIndex) => (
                            slot.slots.map((innrow) => {
                              return (
                                <div className="lot-box vacant" key={slotIndex}>{innrow?.slotName}</div>
                              )
                            })

                          ))}
                        </div>
                      )
                    }
                  })}
                </div>

                <div className="ld-col-content lot-a">
                  <div className="ldc-header">
                    <div className="lot-detail">
                      {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                        console.log(item)
                        if (item?.slotid == '8') {
                          return (
                            <>
                              <h4>
                                {item?.nrow} <small>Rows</small>x {item?.ncol}<small>Bays</small> [<small>Total:</small>{item?.totalcars} <small>Cars</small>]
                              </h4>
                            </>
                          )
                        }
                      })}
                      {/* <p>For cars parked for 3 weeks or more </p> */}
                    </div>
                    {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                      console.log(item)
                      if (item?.slotid == '8') {
                        return (
                          <div className="lot-name">{item?.lotname}</div>
                        )
                      }
                    })}
                  </div>
                  {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                    if (item?.slotid == '8') {
                      return (
                        <div className="ldc-body" key={index}>
                          {item?.innrow.map((slot, slotIndex) => (
                            slot.slots.map((innrow) => {
                              return (
                                <div className="lot-box vacant" key={slotIndex}>{innrow?.slotName}</div>
                              )
                            })

                          ))}
                        </div>
                      )
                    }
                  })}
                </div>

                <div className="ld-col-content lot-a">
                  <div className="ldc-header">
                    <div className="lot-detail">
                      {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                        console.log(item)
                        if (item?.slotid == '9') {
                          return (
                            <>
                              <h4>
                                {item?.nrow} <small>Rows</small>x {item?.ncol}<small>Bays</small> [<small>Total:</small>{item?.totalcars} <small>Cars</small>]
                              </h4>
                            </>
                          )
                        }
                      })}
                      {/* <p>For cars parked for 3 weeks or more </p> */}
                    </div>
                    {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                      console.log(item)
                      if (item?.slotid == '9') {
                        return (
                          <div className="lot-name">{item?.lotname}</div>
                        )
                      }
                    })}
                  </div>
                  {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                    if (item?.slotid == '9') {
                      return (
                        <div className="ldc-body" key={index}>
                          {item?.innrow.map((slot, slotIndex) => (
                            slot.slots.map((innrow) => {
                              return (
                                <div className="lot-box vacant" key={slotIndex}>{innrow?.slotName}</div>
                              )
                            })

                          ))}
                        </div>
                      )
                    }
                  })}
                </div>

                <div className="ld-col-content lot-b">
                  <div className="ldc-header">
                    <div className="lot-detail">
                      {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                        console.log(item)
                        if (item?.slotid == '10') {
                          return (
                            <>
                              <h4>
                                {item?.nrow} <small>Rows</small>x {item?.ncol}<small>Bays</small> [<small>Total:</small>{item?.totalcars} <small>Cars</small>]
                              </h4>
                            </>
                          )
                        }
                      })}
                      {/* <p>Fast Moving</p> */}
                    </div>
                    {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                      console.log(item)
                      if (item?.slotid == '10') {
                        return (
                          <div className="lot-name">{item?.lotname}</div>
                        )
                      }
                    })}
                  </div>
                  {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                    if (item?.slotid == '10') {
                      return (
                        <div className="ldc-body" key={index}>
                          {item?.innrow.map((slot, slotIndex) => (
                            slot.slots.map((innrow) => {
                              return (
                                <div className="lot-box vacant" key={slotIndex}>{innrow?.slotName}</div>
                              )
                            })

                          ))}
                        </div>
                      )
                    }
                  })}
                </div>
                <div className="ld-col-content lot-d margintop710px">
                  <div className="ldc-header">
                    <div className="lot-detail">
                      {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                        console.log(item)
                        if (item?.slotid == '11') {
                          return (
                            <>
                              <h4>
                                {item?.nrow} <small>Rows</small>x {item?.ncol}<small>Bays</small> [<small>Total:</small>{item?.totalcars} <small>Cars</small>]
                              </h4>
                            </>
                          )
                        }
                      })}
                      {/* <p>Fast Moving</p> */}
                    </div>
                    {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                      console.log(item)
                      if (item?.slotid == '11') {
                        return (
                          <div className="lot-name">{item?.lotname}</div>
                        )
                      }
                    })}
                  </div>
                  {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                    if (item?.slotid == '11') {
                      return (
                        <div className="ldc-body" key={index}>
                          {item?.innrow.map((slot, slotIndex) => (
                            slot.slots.map((innrow) => {
                              return (
                                <div className="lot-box vacant" key={slotIndex}>{innrow?.slotName}</div>
                              )
                            })

                          ))}
                        </div>
                      )
                    }
                  })}
                </div>
                <div className="ld-col-content lot-e margintop710px">
                  <div className="ldc-header">
                    <div className="lot-detail">
                      {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                        console.log(item)
                        if (item?.slotid == '12') {
                          return (
                            <>
                              <h4>
                                {item?.nrow} <small>Rows</small>x {item?.ncol}<small>Bays</small> [<small>Total:</small>{item?.totalcars} <small>Cars</small>]
                              </h4>
                            </>
                          )
                        }
                      })}
                      {/* <p>Fast Moving</p> */}
                    </div>
                    {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                      console.log(item)
                      if (item?.slotid == '12') {
                        return (
                          <div className="lot-name">{item?.lotname}</div>
                        )
                      }
                    })}
                  </div>
                  {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                    if (item?.slotid == '12') {
                      return (
                        <div className="ldc-body" key={index}>
                          {item?.innrow.map((slot, slotIndex) => (
                            slot.slots.map((innrow) => {
                              return (
                                <div className="lot-box vacant" key={slotIndex}>{innrow?.slotName}</div>
                              )
                            })

                          ))}
                        </div>
                      )
                    }
                  })}
                </div>
                <div className="ld-col-content lot-office">
                  <div className="office-lot">Office</div>
                </div>

                <div className="ld-col-content lot-r" >
                  <div className="ldc-header">
                    <div className="lot-detail">
                      {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                        console.log(item)
                        if (item?.slotid == '1') {
                          return (
                            <>
                              <h4>
                                {item?.nrow} <small>Rows</small>x {item?.ncol}<small>Bays</small> [<small>Total:</small>{item?.totalcars} <small>Cars</small>]
                              </h4>
                            </>
                          )
                        }
                      })}
                      {/* <p>Fast Moving</p> */}
                    </div>
                    {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                      console.log(item)
                      if (item?.slotid == '1') {
                        return (
                          <div className="lot-name">{item?.lotname}</div>
                        )
                      }
                    })}
                  </div>
                  {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                    if (item?.slotid == '1') {
                      return (
                        <div className="ldc-body" key={index}>
                          {item?.innrow.reverse().map((slot, slotIndex) => (
                            slot.slots.map((innrow, innIndex) => (
                              <div className="lot-box vacant" key={`${slotIndex}-${innIndex}`}>{innrow?.slotName}</div>
                            ))
                          ))}
                        </div>
                      )
                    }
                  })}
                </div>


              </div>
              <div className="ld-col col-lg-2 col-12">
                <div className="ld-col-content lot-30car">
                  <div className="ldc-header">
                    <div className="lot-detail">
                      {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                        console.log(item)
                        if (item?.slotid == '13') {
                          return (
                            <>
                              <h4>
                                {item?.totalcars}<small>Cars</small>
                                {/* {item?.nrow} <small>Rows</small>x {item?.ncol}<small>Bays</small> [<small>Total:</small>{item?.totalcars} <small>Cars</small>] */}
                              </h4>
                            </>
                          )
                        }
                      })}
                    </div>
                    {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                      console.log(item)
                      if (item?.slotid == '13') {
                        return (
                          <div className="lot-name">{item?.lotname}</div>
                        )
                      }
                    })}
                  </div>
                  {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                    if (item?.slotid == '13') {
                      return (
                        <div className="ldc-body" key={index}>
                          {item?.innrow.reverse().map((slot, slotIndex) => (
                            slot.slots.map((innrow) => {
                              return (
                                <div className="lot-box vacant" key={slotIndex}>{innrow?.slotName}</div>
                              )
                            })

                          ))}
                        </div>
                      )
                    }
                  })}
                </div>
              </div>
              <div className="ld-col col-lg-5 col-12">

                <div className="ld-col-content lot-d margintop710px">
                  <div className="ldc-header">
                    <div className="lot-detail">
                      {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                        console.log(item)
                        if (item?.slotid == '7') {
                          return (
                            <>
                              <h4>
                                {item?.nrow} <small>Rows</small>x {item?.ncol}<small>Bays</small> [<small>Total:</small>{item?.totalcars} <small>Cars</small>]
                              </h4>
                            </>
                          )
                        }
                      })}
                      {/* <p>Fast Moving</p> */}
                    </div>
                    {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                      console.log(item)
                      if (item?.slotid == '7') {
                        return (
                          <div className="lot-name">{item?.lotname}</div>
                        )
                      }
                    })}
                  </div>
                  {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                    if (item?.slotid == '7') {
                      return (
                        <div className="ldc-body" key={index}>
                          {item?.innrow.reverse().map((slot, slotIndex) => (
                            slot.slots.reverse().map((innrow, innIndex) => {
                              return (
                                <div className="lot-box vacant" key={`${slotIndex}-${innIndex}`}>{innrow?.slotName}</div>
                              )
                            })

                          ))}
                        </div>
                      )
                    }
                  })}
                </div>

                <div className="ld-col-content lot-d margintop710px">
                  <div className="ldc-header">
                    <div className="lot-detail">
                      {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                        console.log(item)
                        if (item?.slotid == '6') {
                          return (
                            <>
                              <h4>
                                {item?.nrow} <small>Rows</small>x {item?.ncol}<small>Bays</small> [<small>Total:</small>{item?.totalcars} <small>Cars</small>]
                              </h4>
                            </>
                          )
                        }
                      })}
                      {/* <p>Fast Moving</p> */}
                    </div>
                    {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                      console.log(item)
                      if (item?.slotid == '6') {
                        return (
                          <div className="lot-name">{item?.lotname}</div>
                        )
                      }
                    })}
                  </div>
                  {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                    if (item?.slotid == '6') {
                      return (
                        <div className="ldc-body" key={index}>
                          {item?.innrow.reverse().map((slot, slotIndex) => (
                            slot.slots.reverse().map((innrow, innIndex) => (
                              <div className="lot-box vacant" key={`${slotIndex}-${innIndex}`}>{innrow?.slotName}</div>
                            ))
                          ))}
                        </div>
                      )
                    }
                  })}
                </div>

                <div className="ld-col-content lot-e">
                  <div className="ldc-header">
                    <div className="lot-detail">
                      {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                        console.log(item)
                        if (item?.slotid == '5') {
                          return (
                            <>
                              <h4>
                                {item?.nrow} <small>Rows</small>x {item?.ncol}<small>Bays</small> [<small>Total:</small>{item?.totalcars} <small>Cars</small>]
                              </h4>
                            </>
                          )
                        }
                      })}
                      {/* <p>For cars parked for 3 weeks or more </p> */}
                    </div>
                    {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                      console.log(item)
                      if (item?.slotid == '5') {
                        return (
                          <div className="lot-name">{item?.lotname}</div>
                        )
                      }
                    })}
                  </div>
                  {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                    if (item?.slotid == '5') {
                      return (
                        <div className="ldc-body" key={index}>
                          {item?.innrow.reverse().map((slot, slotIndex) => (
                            slot.slots.reverse().map((innrow, innIndex) => (
                              <div className="lot-box vacant" key={`${slotIndex}-${innIndex}`}>{innrow?.slotName}</div>
                            ))
                          ))}
                        </div>
                      )
                    }
                  })}
                </div>
                <div className="ld-col-content lot-r">
                  <div className="ldc-header">
                    <div className="lot-detail">
                      {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                        console.log(item)
                        if (item?.slotid == '4') {
                          return (
                            <>
                              <h4>
                                {item?.nrow} <small>Rows</small>x {item?.ncol}<small>Bays</small> [<small>Total:</small>{item?.totalcars} <small>Cars</small>]
                              </h4>
                            </>
                          )
                        }
                      })}
                      {/* <p>Fast Moving</p> */}
                    </div>
                    {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                      console.log(item)
                      if (item?.slotid == '4') {
                        return (
                          <div className="lot-name">{item?.lotname}</div>
                        )
                      }
                    })}
                  </div>
                  {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                    if (item?.slotid == '4') {
                      return (
                        <div className="ldc-body" key={index}>
                          {item?.innrow.reverse().map((slot, slotIndex) => (
                            slot.slots.reverse().map((innrow, innIndex) => (
                              <div className="lot-box vacant" key={`${slotIndex}-${innIndex}`}>{innrow?.slotName}</div>
                            ))
                          ))}
                        </div>
                      )
                    }
                  })}
                </div>
                <div className="ld-col-content lot-r">
                  <div className="ldc-header">
                    <div className="lot-detail">
                      {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                        console.log(item)
                        if (item?.slotid == '3') {
                          return (
                            <>
                              <h4>
                                {item?.nrow} <small>Rows</small>x {item?.ncol}<small>Bays</small> [<small>Total:</small>{item?.totalcars} <small>Cars</small>]
                              </h4>
                            </>
                          )
                        }
                      })}
                      {/* <p>Fast Moving</p> */}
                    </div>
                    {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                      console.log(item)
                      if (item?.slotid == '3') {
                        return (
                          <div className="lot-name">{item?.lotname}</div>
                        )
                      }
                    })}
                  </div>
                  {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                    if (item?.slotid == '3') {
                      return (
                        <div className="ldc-body" key={index}>
                          {item?.innrow.reverse().map((slot, slotIndex) => (
                            slot.slots.reverse().map((innrow, innIndex) => (
                              <div className="lot-box vacant" key={`${slotIndex}-${innIndex}`}>{innrow?.slotName}</div>
                            ))

                          ))}
                        </div>
                      );
                    }
                  })}
                </div>
                <div className="ld-col-content lot-e-5 marginrTop300px">
                  <div className="ldc-header">
                    <div className="lot-detail">
                      {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                        console.log(item)
                        if (item?.slotid == '2') {
                          return (
                            <>
                              <h4>
                                {item?.nrow} <small>Rows</small>x {item?.ncol}<small>Bays</small> [<small>Total:</small>{item?.totalcars} <small>Cars</small>]
                              </h4>
                            </>
                          )
                        }
                      })}
                      {/* <p>Fast Moving</p> */}
                    </div>
                    {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                      console.log(item)
                      if (item?.slotid == '2') {
                        return (
                          <div className="lot-name">{item?.lotname}</div>
                        )
                      }
                    })}
                  </div>
                  {parkingslotDetails && parkingslotDetails?.map((item, index) => {
                    if (item?.slotid == '2') {
                      return (
                        <div className="ldc-body" key={index}>
                          {item?.innrow.reverse().map((slot, slotIndex) => (
                            slot.slots.map((innrow) => {
                              return (
                                <div className="lot-box vacant" key={slotIndex}>{innrow?.slotName}</div>
                              )
                            })

                          ))}
                        </div>
                      )
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default ParkingSlot;
