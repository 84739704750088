import React, { useState, useEffect } from "react";
import Header from '../../component/Header';
import Sidebar from '../../component/SideBar';
import { image } from "../../common/Theme";
import { Button, Modal, Box, FormControl, TextField, TableBody, InputLabel, FormHelperText, MenuItem, Select, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { columns, countryCodes, genderJson, staticData, updateFields } from "./utils";
import { useSelector } from "react-redux";
import Table from "../../component/Table";
import Apiconnection from "../../utils/Apiconnection";
import TableLoader from "../../utils/TableLoader";
import Loader from "../../utils/Loader";
import { toast } from "react-toastify";
import 'bootstrap/dist/css/bootstrap.min.css';
import Pagination from 'react-bootstrap/Pagination';


const AgentList = () => {

    const [tableloading, settableloading] = useState(false)
    const [editData, setEditData] = useState(null);
    const [agentList, setAgentList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [userImageUrl, setUserImageUrl] = useState(null);
    const [userImageLink, setUserImageLink] = useState('http://3.108.121.124:4041/uploads/userImages/');
    const [openSidebarToggle, setOpenSidebarToggle] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [errors, setErrors] = useState({})
    const { user } = useSelector((state) => state?.user);
    const [firstName, setfirstName] = useState('')
    const [lastName, setlastName] = useState('')
    const [email, setemail] = useState('')
    const [password, setpassword] = useState('')
    const [dialCode, setdialCode] = useState('+1')
    const [phone, setphone] = useState('')
    const [address, setaddress] = useState('')
    const [gender, setgender] = useState('')
    const [parkingGroundId, setparkingGroundId] = useState('')
    const [profileImage, setprofileImage] = useState(null)
    const [addedData, setAddedData] = useState({});
    const [openEditModal, setOpenEditModal] = useState(false);
    const [rateChartData, setRateChartData] = useState([]);
    const [editedData, setEditedData] = useState({});
    const [agentToDelete, setAgentToDelete] = useState(null);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);


    console.log(editedData)

    const [prefilledData, setPrefilledData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        dialCode: '',
        phone: '',
        gender: '',
        address: ''
    });

    const resetForm = () => {
        setfirstName('');
        setlastName('');
        setemail('');
        setdialCode('+1');
        setphone('');
        setgender('');
        setaddress('');
    };

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    const handleOpenEditModal = () => setOpenEditModal(true);
    const handleCloseEditModal = () => setOpenEditModal(false);

    const handleSidebarToggle = () => {
        setOpenSidebarToggle(!openSidebarToggle);
    };

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple regex for email validation
        return emailPattern.test(email);
    };
    


    const OpenSidebar = () => {
        setOpenSidebarToggle(!openSidebarToggle)
    }

        // Calculate current agents to display
        const indexOfLastAgent = currentPage * itemsPerPage;
        const indexOfFirstAgent = indexOfLastAgent - itemsPerPage;
        const currentAgents = agentList.slice(indexOfFirstAgent, indexOfLastAgent);
    
        // Handle page change
        const handlePageChange = (pageNumber) => {
            setCurrentPage(pageNumber);
        };
    
        // Create pagination items
        const totalPages = Math.ceil(agentList.length / itemsPerPage);
        const paginationItems = [];
        for (let number = 1; number <= totalPages; number++) {
            paginationItems.push(
                <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
                    {number}
                </Pagination.Item>
            );
        }

    useEffect(() => {
        if (editedData) {
            setPrefilledData({
                firstName: editedData.user?.firstName || '',
                lastName: editedData.user?.lastName || '',
                email: editedData.user?.email || '',
                dialCode: editedData.user?.dialCode || '+1',
                phone: editedData.user?.phone || '',
                gender: editedData.user?.gender || '',
                address: editedData.user?.address || '',
            });
        }
    }, [editedData]);

    const handleProfileImageChange = (e) => {
        setprofileImage(e.target.files[0]);
    };

    const handleEditInputChange = (event) => {
        const { name, value } = event.target;
        setPrefilledData({ ...prefilledData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (!validateEmail(email)) {
            toast.error('Please enter a valid email address.');
            setLoading(false);
            return;
        }

        try {
            const formData = new FormData();
            formData.append('profileImage', profileImage);
            formData.append('parkingGroundId', 1);
            formData.append('firstName', firstName);
            formData.append('lastName', lastName);
            formData.append('email', email);
            formData.append('dialCode', dialCode);
            formData.append('phone', phone);
            formData.append('gender', gender);
            formData.append('address', address);
            // formData.append('id', 1);
            const response = await Apiconnection.post('agent-add-update', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response?.data?.status) {
                console.log('agent-add-update', response)

                await getAgentListApi();
                toast.success('Added successfully!');
                resetForm();
                handleCloseModal();
                setPrefilledData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    dialCode: '',
                    phone: '',
                    gender: '',
                    address: ''
                });
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error) {
            console.error('Error adding Agent data:', error);
            const errorMessage = error.response?.data?.message || 'An error occurred while adding the agent.';
            toast.error(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (!validateEmail(prefilledData.email)) {
            toast.error('Please enter a valid email address.');
            setLoading(false);
            return;
        }

        try {
            const formData = new FormData();
            formData.append('id', editedData.user.id);
            formData.append('profileImage', editedData.profileImage);
            formData.append('parkingGroundId', editedData.parkingGroundId);
            formData.append('firstName', prefilledData.firstName);
            formData.append('lastName', prefilledData.lastName);
            formData.append('email', prefilledData.email);
            formData.append('dialCode', prefilledData.dialCode);
            formData.append('phone', prefilledData.phone);
            formData.append('gender', prefilledData.gender);
            formData.append('address', prefilledData.address);

            const response = await Apiconnection.post('agent-add-update', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.data.status) {
                await getAgentListApi();
                toast.success('Updated successfully!');
                handleCloseEditModal();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error updating agent data:', error);
            toast.error('Failed to update agent data.');
        } finally {
            setLoading(false);
        }
    };

    const getAgentListApi = async () => {
        settableloading(true);
        try {
            const response = await Apiconnection.post('agent-list');
            console.log('agent-list', response)
            if (response?.data?.status) {
                setAgentList(response.data.data.agentList);
            } else {
                toast.error(response?.message);
            }
        } catch (error) {
            console.error('Error fetching Agent list:', error);
            toast.error('Failed to fetch Agent list.');
        } finally {
            settableloading(false);
        }
    };

    useEffect(() => {
        getAgentListApi();
    }, []);

    const handleDeleteClick = async (row) => {
        setAgentToDelete(row);
        setOpenConfirmDelete(true);
        // try {
        //     const response = await Apiconnection.post('agent-delete', { agentId: row.id });
        //     if (response?.data?.status) {
        //         await getAgentListApi();
        //         toast.success('Deleted successfully!');
        //     } else {
        //         toast.error(response?.message);
        //     }
        // } catch (error) {
        //     console.error('Error deleting Agent:', error);
        //     toast.error('Failed to delete Agent.');
        // }
    };

    const handleConfirmDelete = async () => {
        try {
            const response = await Apiconnection.post('agent-delete', { agentId: agentToDelete.id });
            if (response?.data?.status) {
                await getAgentListApi();
                toast.success('Deleted successfully!');
            } else {
                toast.error(response?.data?.message || 'Failed to delete agent.');
            }
        } catch (error) {
            console.error('Error deleting Agent:', error);
            toast.error('Failed to delete Agent.');
        } finally {
            setOpenConfirmDelete(false);
        }
    };

    const handleEditClick = (row) => {
        setEditedData(row);
        const payload = {
            id: row.id,
        };

        fetchAgentDetails(payload);
        handleOpenEditModal();
    };

    const fetchAgentDetails = async (payload) => {
        try {
            const response = await Apiconnection.post('agent-details', payload);
            if (response.data.status) {
                const userData = response.data.data;
                setPrefilledData({
                    firstName: userData.user.firstName,
                    lastName: userData.user.lastName,
                    email: userData.user.email,
                    dialCode: userData.user.dialCode,
                    phone: userData.user.phone,
                    gender: userData.user.gender,
                    address: userData.user.address,
                });
            } else {
                // toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error fetching agent details:', error);
            // toast.error('Failed to fetch agent details.');
        }
    };

    //   useEffect(() => {
    //     if (editedData) {
    //       const payload = {
    //         id: editedData.id,
    //       };
    //       fetchAgentDetails(payload);
    //     }
    //   }, [editedData]);



    return (
        <div className='grid-container'>
            <Header OpenSidebar={OpenSidebar} />
            <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar}/>
            <div className='user-list'>
                <div className='top-section'>
                    <h3>Agent List</h3>
                    <Button variant="contained" className="action-btn" onClick={handleOpenModal}>
                        <AddIcon />
                        Add Agent
                    </Button>
                </div>

                <div className='custom-table table-responsive'>
                    {!tableloading ?

                        <table className='table '>
                            <thead>
                                <th>Name</th>
                                {/* <th>Image</th> */}
                                <th>Gender</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Address</th>
                                <th>Action</th>
                            </thead>
                            <tbody>
                                {currentAgents && currentAgents.length > 0 ?
                                    currentAgents && currentAgents.map((list, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{list.user.firstName + ' ' + list.user.lastName}</td>
                                                <td>{list.user.gender}</td>
                                                <td>{list.user.email}</td>
                                                <td>{list.user.phone}</td>
                                                <td>{list.user.address}</td>
                                                <td>
                                                    <div className="flx-box">
                                                        <button className="btn btn-sm btn-outline-warning me-2" onClick={() => handleEditClick(list)}>
                                                            <i className="fa-solid fa-pen-to-square"></i>
                                                        </button>
                                                        <button className='btn btn-sm btn-outline-danger' onClick={() => handleDeleteClick(list)}>
                                                            <i className="fa-solid fa-trash"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr>
                                        <td colSpan="8" className='text-center'>No data record</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                        :
                        <TableLoader />
                    }
                </div>
                <Pagination className="justify-content-end">
                    {paginationItems}
                </Pagination>

                <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="add-user-modal"
                    aria-describedby="add-user-modal-description"
                >
                    <div className="right-open-modal">
                        <h2 id="modal-title">Add Agent</h2>
                        <form onSubmit={handleSubmit}>
                            <TextField
                                id="firstName"
                                label="First Name"
                                className="mt-3"
                                value={firstName}
                                onChange={(e) => setfirstName(e.target.value)}
                                name="firstName"
                                required
                            />
                            <TextField
                                id="lastName"
                                label="Last Name"
                                value={lastName}
                                name="lastName"
                                onChange={(e) => setlastName(e.target.value)}
                                required
                            />
                            <TextField
                                id="email"
                                label="Email"
                                name="email"
                                value={email}
                                onChange={(e) => setemail(e.target.value)}
                                required
                            />
                            <InputLabel>Dialcode</InputLabel>
                            <Select label='Dialcode' id="dialCode" required value={dialCode} onChange={(e) => setdialCode(e.target.value)} name="dialCode" >
                                {countryCodes.map((countryCode) => (
                                    <MenuItem value={countryCode.value}>{countryCode.value} </MenuItem>
                                ))}
                            </ Select>
                            <FormHelperText>{errors?.dialCode}</FormHelperText>
                            <TextField
                                id="phone"
                                label="Mobile Number"
                                name="phone"
                                inputProps={{
                                    maxLength: 10,
                                    pattern: "[0-9]*",
                                    onInput: (e) => {
                                        e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Replace non-numeric characters
                                    }
                                }}
                                value={phone}
                                onChange={(e) => setphone(e.target.value)}
                                required
                            />
                            <InputLabel>Gender</InputLabel>
                            <Select label='Gender' id="gender" required value={gender} onChange={(e) => setgender(e.target.value)} name="gender" >
                                {genderJson.map((gender) => (
                                    <MenuItem value={gender.value}>{gender.value} </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{errors?.gender}</FormHelperText>
                            <TextField
                                id="address"
                                label="Address"
                                name="address"
                                value={address}
                                onChange={(e) => setaddress(e.target.value)}
                            />

                            {/* <input
                                id="profileImage"
                                type="file"
                                onChange={handleProfileImageChange}
                                name="profileImage"
                                style={{ marginBottom: '20px' }}
                            /> */}
                            <Button type="submit" variant="contained" color="primary">
                                Submit
                            </Button>
                        </form>
                    </div>
                </Modal>

                <Modal
                    open={openEditModal}
                    onClose={handleCloseEditModal}
                    aria-labelledby="add-user-modal"
                    aria-describedby="add-user-modal-description"
                >
                    <div className="right-open-modal">
                        <h2 className="mb-3" id="modal-title">Edit Agent</h2>
                        <form onSubmit={handleEditSubmit}>
                            <TextField
                                id="firstName"
                                label="First Name"
                                className="mt-3"
                                value={prefilledData.firstName}
                                onChange={handleEditInputChange}
                                name="firstName"
                                required
                            />
                            <TextField
                                id="lastName"
                                label="Last Name"
                                value={prefilledData.lastName}
                                name="lastName"
                                onChange={handleEditInputChange}
                                required
                            />
                            <TextField
                                id="email"
                                label="Email"
                                name="email"
                                value={prefilledData.email}
                                onChange={handleEditInputChange}
                                required
                            />
                            <InputLabel>Dialcode</InputLabel>
                            <Select label='Dialcode' id="dialCode" required value={prefilledData.dialCode} onChange={handleEditInputChange} name="dialCode" >
                                {countryCodes.map((countryCode) => (
                                    <MenuItem value={countryCode.value} key={countryCode.value}>{countryCode.value} </MenuItem>
                                ))}
                            </ Select>
                            <FormHelperText>{errors?.dialCode}</FormHelperText>
                            <TextField
                                id="phone"
                                label="Mobile Number"
                                name="phone"
                                inputProps={{
                                    maxLength: 10,
                                    pattern: "[0-9]*",
                                    onInput: (e) => {
                                        e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Replace non-numeric characters
                                    }
                                }}
                                value={prefilledData.phone}
                                onChange={handleEditInputChange}
                                required
                            />
                            <InputLabel>Gender</InputLabel>
                            <Select label='Gender' id="gender" required value={prefilledData.gender} onChange={handleEditInputChange} name="gender" >
                                {genderJson.map((gender) => (
                                    <MenuItem value={gender.value}>{gender.value} </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{errors?.gender}</FormHelperText>
                            <TextField
                                id="address"
                                label="Address"
                                name="address"
                                value={prefilledData.address}
                                onChange={handleEditInputChange}
                            />

                            {/* <input
                                id="profileImage"
                                type="file"
                                name="profileImage"
                                style={{ marginBottom: '20px' }}
                            /> */}
                            <Button type="submit" variant="contained" color="primary"> Submit </Button>
                        </form>
                    </div>
                </Modal>
            </div>
            {/* <div className='inner-container'> */}
            <Modal
                open={openConfirmDelete}
                onClose={() => setOpenConfirmDelete(false)}
                aria-labelledby="confirm-delete-modal"
                aria-describedby="confirm-delete-modal-description"
                className="rate-modal"
            >
                <Box component="form"
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        fontSize: 15,
                        height: 200,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }} >
                    <h2 id="modal-title" > Confirm Delete </h2>
                    <div className="mdl-inner">
                        <p className="mb-5 mt-4" style={{fontWeight: '600'}}> Are you sure you want to delete this agent ? </p>
                        <div className="flx-box">
                            <Button variant="contained" style={{ marginLeft: '44px' }} color="primary" onClick={handleConfirmDelete} > Yes, Delete </Button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            < Button variant="contained" color="secondary" onClick={() => setOpenConfirmDelete(false)}> Cancel </Button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
        // </div>
    )
}

export default AgentList;