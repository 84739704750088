import { Outlet, Navigate } from 'react-router-dom'

const Publicroute = () => {
    const token = localStorage.getItem('prime_access_token');

    return (
      token ? <Outlet/> : <Navigate to="/"  />
    )
}

export default Publicroute