export const updateFields = [
    {
        field_name: 'First Name',
        field_key: 'firstName',
        type: 'string',
        required: true,
    },
    {
        field_name: 'Last Name',
        field_key: 'lastName',
        type: 'string',
        required: true,
    },
    {
        field_name: 'Email',
        field_key: 'email',
        type: 'email',
        required: true,
    },
    {
        field_name: 'Password',
        field_key: 'password',
        type: 'password',
        required: true,
        minLength: 8, 
    },
    {
        field_name: 'Dial Code',
        field_key: 'dialCode',
        type: 'string',
        required: true,
    },
    {
        field_name: 'Phone Number',
        field_key: 'phone',
        type: 'string',
        required: true,
        pattern: /^[0-9]+$/, 
    },
    {
        field_name: 'Date of Birth',
        field_key: 'dob',
        type: 'date',
        required: true,
    },
    {
        field_name: 'Gender',
        field_key: 'gender',
        type: 'string',
        required: true,
        enum: ['Male', 'Female', 'Other'], 
    },
    {
        field_name: 'Address',
        field_key: 'address',
        type: 'string',
        required: true, 
    },
    {
        field_name: 'Module Access',
        field_key: 'moduleAccess',
        type: 'array',
        required: true,
        minLength: 1, 
    },
];

export const countryCodes = [
    { value: '+1', label: 'USA' },
    { value: '+44', label: 'UK' },
    { value: '+33', label: 'France' },
    { value: '+91', label: 'India' },
];

export const genderJson = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
    { value: 'Others', label: 'Others' },

    
];


export const columns = [
    { field: 'firstName', headerName: 'First Name' },
    { field: 'lastName', headerName: 'Last Name' },
    { field: 'email', headerName: 'Email' },
    // { field: 'password', headerName: 'Password' },
    { field: 'dialCode', headerName: 'Dial Code' },
    { field: 'phone', headerName: 'Phone' },
    { field: 'dob', headerName: 'Date of Birth' },
    { field: 'gender', headerName: 'Gender' },
    { field: 'address', headerName: 'Address' },
    { field: 'profileImage', headerName: 'Profile Image' },
    {
        field: 'action',
        headerName: 'Action',
        width: 150,
        renderCell: (params) => {
            console.log('params', params);
            return (
                <button onClick={console.log('ss', params.row.id)}> Click </button>
              )
        },
      },
  ];

export  const staticData = [
    { id: 1, name: 'John Doe', age: 25, email: 'john@example.com' },
    { id: 2, name: 'Jane Smith', age: 32, email: 'jane@example.com' },
    { id: 3, name: 'Alex Johnson', age: 45, email: 'alex@example.com' },
    { id: 4, name: 'Emily Davis', age: 29, email: 'emily@example.com' },
  ];
