import React, { useEffect, useState } from "react";
import Header from '../../component/Header';
import Sidebar from '../../component/SideBar';
import {
    Table, TableBody, Modal, TableCell, TableContainer, TableHead, TableRow, Paper,
    TextField, Select, MenuItem, Button, Toolbar, Typography, FormControl, InputLabel, TablePagination, FormHelperText
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Navigate, useNavigate } from 'react-router-dom';
import Apiconnection from "../../utils/Apiconnection";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import moment from "moment";


const BookingList = () => {
    const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
    const [search, setSearch] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [listType, setListType] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [openModal, setOpenModal] = useState(false)
    const [bookings, setBookings] = useState([]);
    const [filteredBookings, setFilteredBookings] = useState([]);
    const [totalBookings, setTotalBookings] = useState(0);
    const [passengerCount, setPassengerCount] = useState(4);
    const [agentList, setAgentList] = useState([]);
    const [vehicleTypes, setVehicleTypes] = useState([]);
    const [errors, setErrors] = useState({});

    const [openCheckInModal, setOpenCheckInModal] = useState(false);
    const [checkInForm, setCheckInForm] = useState({
        actualvehicleTypeId: "",
        actualFromDate: moment().format('YYYY-MM-DD'),
        actualCheckInTime: moment().format('HH:mm'),
        slotId: "",
        plateNumber: ""
    });

    const [openCheckOutModal, setOpenCheckOutModal] = useState(false);
    const [checkOutForm, setCheckOutForm] = useState({
        bookingRequestId: '',
        actualToDate: moment().format('YYYY-MM-DD'),
        actualCheckOutTime: moment().format('HH:mm')
    });

    const OpenSidebar = () => {
        setOpenSidebarToggle(!openSidebarToggle);
    };

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
        // setSearch(searchValue);

        // // Filter bookings based on search value
        // const filtered = bookings.filter(booking =>
        //     booking.bookingTicketNo.includes(searchValue) ||
        //     booking.customerMobile.toString().includes(searchValue) ||
        //     booking.plateNumber.includes(searchValue)
        // );
        // setFilteredBookings(filtered);
        // setPage(0); // Reset to first page when search changes
    };

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    const handleListTypeChange = (event) => {
        setListType(event.target.value);
    };


    const handleOpenModal = () => {
        setOpenModal(true)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleCloseModal = () => {
        setOpenModal(false)
    }



    const navigate = useNavigate();

    const handleCheckIn = (hasReservation) => {
        if (hasReservation) {
            navigate('/customer-booking');
        } else {
            navigate('/add-booking');
        }
        setOpenModal(false);
    };

    const fetchVehicleTypes = async () => {
        try {
            const response = await Apiconnection.post('vehicle-type-list');
            if (response.data.status) {
                setVehicleTypes(response.data.data);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error fetching vehicle types:', error);
            toast.error('Failed to fetch vehicle types.');
        }
    };

    useEffect(() => {
        getAgentListApi();
        fetchVehicleTypes();
    }, []);

    const fetchBookings = async (page, limit) => {
        try {
            const response = await Apiconnection.post(`booking-request-list?page=${page}&limit=${limit}`);
            if (response.data.status) {
                setBookings(response.data.data);
                setFilteredBookings(response.data.data);
                setTotalBookings(response.data.totalCount);
                console.log(response.data.totalCount)
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error fetching bookings:', error);
            toast.error('Failed to fetch bookings.');
        }
    };

    useEffect(() => {
        fetchBookings(page + 1, rowsPerPage);
    }, [page, rowsPerPage]);

    const shouldDisableDate = (date) => {
        return date.isAfter(moment());
    };

    // Function to disable future times
    const shouldDisableTime = (time) => {
        const currentTime = moment();
        return time.isAfter(currentTime);
    };

    // useEffect(() => {
    //     const getBookings = async () => {
    //         try {
    //             const response = await Apiconnection.post('booking-request-list');
    //             if (response && response.data) {
    //                 setBookings(response.data.data);
    //             } else {
    //                 console.error('Error fetching bookings:', response.data.message);
    //             }
    //         } catch (error) {
    //             console.error('Error fetching bookings:', error);
    //         }
    //     };
    //     getBookings();
    // }, []);

    // const filteredBookings = bookings.filter(booking =>
    //     booking.bookingTicketNo.includes(search) ||
    //     booking.customerMobile.toString().includes(search) ||
    //     booking.plateNumber.includes(search)
    // );

    // const paginatedBookings = filteredBookings.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);


    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const totalPages = Math.ceil(totalBookings / rowsPerPage);
    console.log('totalPages',totalPages)

    const paginatedBookings = filteredBookings.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);


    const handleViewInvoice = async (id) => {
        navigate('/booking-receipts', { state: id });

        // try {
        //     const response = await Apiconnection.post('invoice-detail', { "bookingRequestId": id });
        //     console.log('invoice-detail', response)
        // } catch (error) {
        //     console.error('Error fetching Invoice details:', error);
        // }
    }

    const handleUpdateCheckIn = async (id) => {
        try {
            const response = await Apiconnection.post('booking-request-detail', { "bookingRequestId": id });
            if (response.data.status) {
                const bookingDetails = response.data.data;
                const defaultVehicleTypeId = vehicleTypes.find(type => type.typeName === "Sedan")?.id;
                setCheckInForm({
                    bookingRequestId: bookingDetails?.id,
                    // actualvehicleTypeId: bookingDetails.actualvehicleTypeId,
                    actualvehicleTypeId: defaultVehicleTypeId || "",
                    // actualFromDate: bookingDetails.actualFromDate,
                    // actualCheckInTime: bookingDetails.actualCheckInTime,
                    actualFromDate: moment().format('YYYY-MM-DD'),
                    
                    actualCheckInTime: moment().format('HH:mm'),
                    slotId: bookingDetails.slotId,
                    slotName: bookingDetails.slot.slotName,
                    plateNumber: bookingDetails.plateNumber
                });
                setOpenCheckInModal(true);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error fetching booking details:', error);
            toast.error('Failed to fetch booking details.');
        }
    };

    const handleCheckInFormSubmit = async (event) => {
        event.preventDefault();
        try {
            const payload = {
                "bookingRequestId": checkInForm.bookingRequestId,
                "actualvehicleTypeId": checkInForm.actualvehicleTypeId,
                "actualFromDate": checkInForm.actualFromDate,
                "actualCheckInTime": checkInForm.actualCheckInTime,
                "slotId": checkInForm.slotId,
                "plateNumber": checkInForm.plateNumber,
                "passengerCount": passengerCount
            };
            const response = await Apiconnection.post('mark-check-in', payload);
            if (response.data.status) {
                const updatedBookings = bookings.map((booking) => {
                    if (booking.id === payload.bookingRequestId) {
                        return { ...booking, bookingStatus: "Checked In" };
                    }
                    return booking;
                });
                setBookings(updatedBookings);
                toast.success('Check-in successful!');

                const calculateChargesPayload = {
                    "bookingRequestId": response.data.data.updatedBookingRequest.id,
                    "vehicleTypeId": response.data.data.updatedCarMovement.actualvehicleTypeId,
                    "passengerCount": passengerCount,
                    "checkinDate": response.data.data.updatedCarMovement.actualFromDate,
                    "startTime": response.data.data.updatedCarMovement.actualCheckInTime,
                    "checkoutDate": response.data.data.updatedBookingRequest.toDate,
                    "endTime": response.data.data.updatedBookingRequest.selectedCheckOutTime
                };

                const calculateChargesResponse = await Apiconnection.post('calculate-booking-charges-after-check-in', calculateChargesPayload);
                if (calculateChargesResponse.data.status) {
                    console.log('Booking charges calculated successfully:', calculateChargesResponse.data);
                } else {
                    toast.error(calculateChargesResponse.data.message);
                }

                setOpenCheckInModal(false);
                fetchBookings();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error updating check-in:', error);
            toast.error('Failed to update check-in.');
        }
    };

    const handleUpdateCheckOut = async (id) => {
        try {
            const response = await Apiconnection.post('booking-request-detail', { "bookingRequestId": id });
            if (response.data.status) {
                const bookingDetails = response.data.data;
                setCheckOutForm({
                    bookingRequestId: bookingDetails.id,
                    actualToDate: moment().format('YYYY-MM-DD'),
                    actualCheckOutTime: moment().format('HH:mm')
                });
                setOpenCheckOutModal(true);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error fetching booking details:', error);
            toast.error('Failed to fetch booking details.');
        }
    };

    const handleCheckOutFormSubmit = async (event) => {
        event.preventDefault();
        try {
            const payload = {
                "bookingRequestId": checkOutForm.bookingRequestId,
                "actualToDate": checkOutForm.actualToDate,
                "actualCheckOutTime": checkOutForm.actualCheckOutTime
            };
            const response = await Apiconnection.post('mark-check-out', payload);
            if (response.data.status) {
                const updatedBookings = bookings.map((booking) => {
                    if (booking.id === payload.bookingRequestId) {
                        return { ...booking, bookingStatus: "Checked Out" };
                    }
                    return booking;
                });
                setBookings(updatedBookings);
                toast.success('Check-out successful!');

                const calculateChargesPayload = {
                    "bookingRequestId": response.data.data.updatedBookingRequest.id,
                    "checkoutDate": response.data.data.updatedCarMovement.actualToDate,
                    "endTime": response.data.data.updatedCarMovement.actualCheckOutTime
                };
                const calculateChargesResponse = await Apiconnection.post('calculate-booking-charges-after-check-out', calculateChargesPayload);
                if (calculateChargesResponse.data.status) {
                    console.log('Booking charges calculated successfully:', calculateChargesResponse.data);
                } else {
                    toast.error(calculateChargesResponse.data.message);
                }

                setOpenCheckOutModal(false);
                fetchBookings()
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error updating check-out:', error);
            toast.error('Failed to update check-out.');
        }
    };

    const getAgentListApi = async () => {
        try {
            const response = await Apiconnection.post('agent-list');
            console.log('agent-list', response)
            if (response?.data?.status) {
                setAgentList(response.data.data.agentList);
            } else {
                toast.error(response?.message);
            }
        } catch (error) {
            console.error('Error fetching Agent list:', error);
            toast.error('Failed to fetch Agent list.');
        } finally {
        }
    };

    const handleStatusChange = (event, id) => {
        const updatedBookings = bookings.map((booking) => {
            if (booking.id === id) {
                return { ...booking, bookingStatus: event.target.value };
            }
            return booking;
        });
        setBookings(updatedBookings);
    };

    const handleAssignStatusChange = async (event, id) => {
        try {
            const payload = {
                "agentId": event.target.value,
                "bookingRequestId": id
            };

            const response = await Apiconnection.post('assign-agent-to-booking-request', payload);

            if (response.data.status) {
                const updatedBookings = bookings.map((booking) => {
                    if (booking.id === id) {
                        return { ...booking, bookingStatus: event.target.value };
                    }
                    return booking;
                });
                setBookings(updatedBookings);
                toast.success('Agent assigned successfully!');
                fetchBookings(page + 1, rowsPerPage);
            } else {
                toast.error(response.data.message || 'Failed to assign agent.');
            }
        } catch (error) {
            console.error('Error assigning agent:', error);
            toast.error(error.response.data.message);
        }
    };

    const handleUpdateStatus = (event, id) => {
        const updatedBookings = bookings.map((booking) => {
            if (booking.id === id) {
                return { ...booking, paymentStatus: event.target.value };
            }
            return booking;
        });
        setBookings(updatedBookings);
    };

    const handleActionChanges = async (event, id) => {
        const newStatus = event.target.value;

        const bookingToUpdate = bookings.find(booking => booking.id === id);
        const currentReadyStatus = bookingToUpdate ? bookingToUpdate.readyStatus : "";

        const payload = {
            bookingRequestId: id,
            readyStatus: currentReadyStatus === "Requested" ? "Ready" : "Not Ready"
        };

        try {
            const response = await Apiconnection.post('car-ready-status-change', payload);

            if (response.data.status) {
                const updatedBookings = bookings.map((booking) => {
                    if (booking.id === id) {
                        return { ...booking, readyStatus: payload.readyStatus };
                    }
                    return booking;
                });
                setBookings(updatedBookings);
                toast.success('Status updated successfully!');
                fetchBookings();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error updating status:', error);
            toast.error('Failed to update status.');
        }
    };

    const handleActionChange = (event, id) => {
        const updatedBookings = bookings.map((booking) => {
            if (booking.id === id) {
                return { ...booking, paymentStatus: event.target.value };
            }
            return booking;
        });
        setBookings(updatedBookings);
    };

    // Sample data
    const rows = [
        { ticketId: 'T001', customerDetails: 'John Doe', lotSlot: 'Lot A, Slot 1', resvId: 'R001', pickupDateTime: '2024-09-01 10:00 AM', assignedTo: 'Agent A', status: 'Pending' },
        { ticketId: 'T002', customerDetails: 'Jane Smith', lotSlot: 'Lot B, Slot 2', resvId: 'R002', pickupDateTime: '2024-09-02 11:00 AM', assignedTo: 'Agent B', status: 'Completed' },
        { ticketId: 'T001', customerDetails: 'John Doe', lotSlot: 'Lot A, Slot 1', resvId: 'R001', pickupDateTime: '2024-09-01 10:00 AM', assignedTo: 'Agent A', status: 'Pending' },
        { ticketId: 'T002', customerDetails: 'Jane Smith', lotSlot: 'Lot B, Slot 2', resvId: 'R002', pickupDateTime: '2024-09-02 11:00 AM', assignedTo: 'Agent B', status: 'Completed' },
        { ticketId: 'T001', customerDetails: 'John Doe', lotSlot: 'Lot A, Slot 1', resvId: 'R001', pickupDateTime: '2024-09-01 10:00 AM', assignedTo: 'Agent A', status: 'Pending' },
        { ticketId: 'T002', customerDetails: 'Jane Smith', lotSlot: 'Lot B, Slot 2', resvId: 'R002', pickupDateTime: '2024-09-02 11:00 AM', assignedTo: 'Agent B', status: 'Completed' },
        // Add more sample rows as needed
    ];

    const filteredRows = rows.filter(row =>
        row?.ticketId?.includes(search) ||
        row?.customerDetails?.toLowerCase()?.includes(search.toLowerCase())
    );

    const rowsPerPageOptions = [5, 10, 15, 20, 25, totalBookings];

    const paginatedRows = filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    // const paginatedBookings = bookings.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);




    return (


        <div className='grid-container'>
            <Header OpenSidebar={OpenSidebar} />
            <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar}/>
            <div className='inner-container'>
                <div className='custom-table'>
                    <Toolbar>
                        <Typography variant="h6" style={{ flex: 1 }}>
                            Booking List
                        </Typography>
                        {/* <TextField
                            label="Search"
                            variant="outlined"
                            size="small"
                            value={search}
                            onChange={handleSearchChange}
                            style={{ fontSize: '20px' }}
                        />
                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <FormControl fullWidth >
                                <DatePicker
                                    label="Select Date"
                                    type="date"
                                    name="dob"

                                    onChange={handleDateChange}
                                    format="DD-MM-YYYY"
                                />

                            </FormControl>
                        </LocalizationProvider>

                        <FormControl variant="outlined" size="small" style={{ marginRight: '20px' }}>
                            <InputLabel>Select List Type</InputLabel>
                            <Select
                                value={listType}
                                onChange={handleListTypeChange}
                                label="Select List Type"
                            >
                                <MenuItem value=""><em>None</em></MenuItem>
                                <MenuItem value="Type1">Type 1</MenuItem>
                                <MenuItem value="Type2">Type 2</MenuItem>
                            </Select>
                        </FormControl> */}

                        <Button variant="contained" className="action-btn" onClick={handleOpenModal}>
                            <AddIcon />  CHECK IN
                        </Button>
                        <Modal
                            open={openModal}
                            onClose={handleCloseModal}
                            aria-labelledby="add-user-modal"
                            aria-describedby="add-user-modal-description">

                            <div className="booking-modal" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '0px', border: '1px solid gray', borderRadius: '5px' }}>
                                <h2 id="modal-title">Check In</h2>
                                <h6>Does the Customer have a reservation?</h6>
                                <div id="modal-description">
                                    <Button variant="contained" className="yes-btn" color="primary" onClick={() => handleCheckIn(true)}>Yes</Button>
                                    <Button variant="contained" className="no-btn" color="secondary" onClick={() => handleCheckIn(false)}>No</Button>
                                </div>
                            </div>
                        </Modal>

                    </Toolbar>

                    <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Ticket ID</TableCell>
                                    <TableCell>Customer Details</TableCell>
                                    <TableCell>Slot</TableCell>
                                    {/* <TableCell>Resv. ID</TableCell> */}
                                    <TableCell>Schedule Date & Time</TableCell>
                                    <TableCell>Assigned To</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Action</TableCell>
                                    <TableCell>Invoice</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {filteredBookings.length > 0 ? (
                                    filteredBookings.map((booking) => (
                                        <TableRow key={booking.id}>
                                            <TableCell>{booking.bookingTicketNo}</TableCell>
                                            <TableCell>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div style={{
                                                        width: '30px',
                                                        height: '30px',
                                                        borderRadius: '50%',
                                                        backgroundColor: '#ccc',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        marginRight: '10px',
                                                        fontSize: '16px',
                                                        fontWeight: 'bold',
                                                        color: '#fff'
                                                    }}>
                                                        {booking.user.firstName.charAt(0).toUpperCase() + booking.user.lastName.charAt(0).toUpperCase()}
                                                    </div>
                                                    <span>{booking.user.firstName} {booking.user.lastName}<br></br>{booking.customerMobile}</span>
                                                </div>
                                            </TableCell>
                                            <TableCell>{booking.slot.slotName}</TableCell>
                                            <TableCell>
                                                <div>
                                                    <div>{booking.fromDate} | {booking.selectedCheckInTime}</div>
                                                    <div style={{ textAlign: 'center' }}>To </div>
                                                    <div>{booking.toDate} | {booking.selectedCheckOutTime}</div>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <FormControl variant="standard" sx={{ width: 120 }}>
                                                    <Select
                                                        value={booking.agentBookingMapping ? booking.agentBookingMapping.agentId : ""}
                                                        disabled={booking.bookingStatus === "Checked Out"}
                                                        sx={{
                                                            borderRadius: 2,
                                                            border: '1px solid #ccc',
                                                            padding: '5px 10px',
                                                            fontSize: 14,
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                border: '1px solid #ccc',
                                                                '&:hover': {
                                                                    border: '1px solid #ccc',
                                                                },
                                                                '&.Mui-focused': {
                                                                    border: '1px solid #ccc',
                                                                    boxShadow: 'none',
                                                                },
                                                            },
                                                        }}
                                                        onChange={(event) => handleAssignStatusChange(event, booking.id)}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                style: {
                                                                    position: 'absolute',
                                                                    top: 'auto',
                                                                    left: 'auto',
                                                                    right: 'auto',
                                                                    transform: 'translateY(0)',
                                                                },
                                                            },
                                                        }}
                                                        renderValue={(selected) => {
                                                            return selected === "" ? <em>Select Agent</em> : agentList.find(item => item.id === selected)?.user.firstName + " " + agentList.find(item => item.id === selected)?.user.lastName;
                                                        }}
                                                    >
                                                        <MenuItem value=""><em>Select Agent</em></MenuItem>
                                                        {agentList.map((item) => (
                                                            <MenuItem key={item.id} value={item.id}>
                                                                {item.user.firstName} {item.user.lastName}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </TableCell>

                                            <TableCell>
                                                <Button
                                                    variant="contained"
                                                    color={booking.readyStatus === "Ready" ? "secondary" : "primary"}
                                                    onClick={(event) => handleActionChanges(event, booking.id)}
                                                    disabled={booking.readyStatus === "Not Requested" || booking.readyStatus === "Ready"}
                                                >
                                                    {booking.readyStatus === "Requested" ? "Ready" : "Ready"}
                                                </Button>
                                            </TableCell>

                                            <TableCell>
                                                <div>
                                                    {booking.bookingStatus === "Booked" ? (
                                                        <Button variant="contained" color="primary" onClick={() => handleUpdateCheckIn(booking.id)} sx={{ fontSize: 12 }}>
                                                            Check In
                                                        </Button>
                                                    ) : booking.bookingStatus === "Checked In" ? (
                                                        <Button variant="contained" color="secondary" onClick={() => handleUpdateCheckOut(booking.id)} sx={{ fontSize: 12 }}>
                                                            Check Out
                                                        </Button>
                                                    ) : booking.bookingStatus === "Checked Out" ? (
                                                        <Button variant="contained" color="secondary" disabled sx={{ fontSize: 12 }}>
                                                            Checked Out
                                                        </Button>
                                                    ) : booking.bookingStatus === "Failed" ? (
                                                        <Button variant="contained" color="error" disabled sx={{ fontSize: 12 }}>
                                                            Failed
                                                        </Button>
                                                    ) : (
                                                        <Button variant="contained" color="primary" onClick={() => handleUpdateCheckIn(booking.id)} sx={{ fontSize: 12 }}>
                                                            Check In
                                                        </Button>
                                                    )}

                                                </div>
                                            </TableCell>
                                            <TableCell><i class='fas fa-eye' style={{cursor: 'pointer'}} onClick={() => handleViewInvoice(booking.id)}></i></TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={8} align="center">No bookings found.</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15, 20, 25, totalBookings]}
                        component="div"
                        count={totalBookings}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        nextButtonProps={{ disabled: page >= Math.ceil(filteredBookings.length / rowsPerPage) - 1 }}
                        backButtonProps={{ disabled: page === 0 }}
                    />
                </div>
                <Modal
                    open={openCheckInModal}
                    onClose={() => setOpenCheckInModal(false)}
                    aria-labelledby="check-in-modal"
                    aria-describedby="check-in-modal-description"
                >
                    <div className="check-in-modal" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', border: '1px solid gray', borderRadius: '5px' }}>
                        <h2 id="check-in-modal-title" className="mb-4">Check In</h2>
                        <form onSubmit={handleCheckInFormSubmit}>
                            <FormControl fullWidth error={errors?.actualvehicleTypeId}>
                                <InputLabel id="actualvehicleTypeId-label">Vehicle Type</InputLabel>
                                <Select
                                    labelId="actualvehicleTypeId-label"
                                    id="actualvehicleTypeId"
                                    value={checkInForm.actualvehicleTypeId}
                                    onChange={(event) => setCheckInForm({ ...checkInForm, actualvehicleTypeId: event.target.value })}
                                >
                                    {vehicleTypes.map((vehicleType) => (
                                        <MenuItem value={vehicleType.id}>{vehicleType.typeName}</MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{errors?.actualvehicleTypeId}</FormHelperText>
                            </FormControl>

                            <TextField
                            label="Number of Passengers"
                            type="number"
                            variant="outlined"
                            size="small"
                            value={passengerCount}
                            onChange={(event) => {
                                const value = Math.max(1, Math.min(20, event.target.value)); // Clamp value between 1 and 20
                                setPassengerCount(value);
                            }}
                            inputProps={{ min: 1, max: 20 }}
                            style={{ marginBottom: '10px' }}
                        />

                            <div className="d-flex cus-gap">
                                <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Check In Date"
                                        disabled
                                        value={checkInForm.actualFromDate ? moment(checkInForm.actualFromDate) : null}
                                        onChange={(newValue) => {
                                            setCheckInForm({ ...checkInForm, actualFromDate: newValue.format('YYYY-MM-DD') });
                                        }}
                                        shouldDisableDate={shouldDisableDate}
                                        renderInput={(params) => (
                                            <TextField {...params} />
                                        )}
                                    />
                                </LocalizationProvider>

                                <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                        label="Check In Time"
                                        disabled
                                        value={checkInForm.actualCheckInTime ? moment(checkInForm.actualCheckInTime, 'HH:mm') : null}
                                        onChange={(newValue) => {
                                            setCheckInForm({ ...checkInForm, actualCheckInTime: newValue.format('HH:mm') });
                                        }}
                                        shouldDisableTime={shouldDisableTime}
                                        renderInput={(params) => (
                                            <TextField {...params} />
                                        )}
                                    />
                                </LocalizationProvider>
                            </div>

                            <TextField
                                label="Slot Name"
                                variant="outlined"
                                size="small"
                                value={checkInForm.slotName}
                                onChange={(event) => setCheckInForm({ ...checkInForm, slotId: event.target.value })}
                                style={{ marginBottom: '10px' }}
                            />
                            <TextField
                                label="Plate Number"
                                variant="outlined"
                                size="small"
                                value={checkInForm.plateNumber}
                                onChange={(event) => setCheckInForm({ ...checkInForm, plateNumber: event.target.value })}
                                style={{ marginBottom: '10px' }}
                            />
                            <Button variant="contained" color="primary" type="submit" style={{ marginTop: '10px', float: 'right' }}>
                                Submit
                            </Button>
                        </form>
                    </div>
                </Modal>

                <Modal
                    open={openCheckOutModal}
                    onClose={() => setOpenCheckOutModal(false)}
                    aria-labelledby="check-out-modal"
                    aria-describedby="check-out-modal-description"
                >
                    <div className="check-out-modal" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', border: '1px solid gray', borderRadius: '5px' }}>
                        <h2 id="check-out-modal-title" className="mb-4">Check Out</h2>
                        <form onSubmit={handleCheckOutFormSubmit}>
                            <div className="d-flex cus-gap">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker disabled
                                        label="Actual Check Out Date"
                                        value={checkOutForm.actualToDate ? moment(checkOutForm.actualToDate) : null}
                                        onChange={(newValue) => {
                                            setCheckOutForm({ ...checkOutForm, actualToDate: newValue.format('YYYY-MM-DD') });
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} />
                                        )}
                                    />
                                </LocalizationProvider>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                        disabled
                                        label="Actual Check Out Time"
                                        value={checkOutForm.actualCheckOutTime ? moment(checkOutForm.actualCheckOutTime, 'HH:mm') : moment()} // Use current time if no value
                                        onChange={(newValue) => {
                                            setCheckOutForm({ ...checkOutForm, actualCheckOutTime: newValue.isValid() ? newValue.format('HH:mm') : '' });
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} />
                                        )}
                                    />
                                </LocalizationProvider>
                            </div>
                            <Button variant="contained" color="primary" type="submit" style={{ marginTop: '10px', float: 'right' }}>Check Out</Button>
                        </form>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default BookingList;
