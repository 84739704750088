import React from "react";
import { useState } from 'react'
import Header from '../../component/Header';
import Sidebar from '../../component/SideBar';
import { image } from "../../common/Theme";

const Setting = () => {
    const [openSidebarToggle, setOpenSidebarToggle] = useState(false)

    const OpenSidebar = () => {
        setOpenSidebarToggle(!openSidebarToggle)
    }

    return (
        <div className='grid-container'>
            <Header OpenSidebar={OpenSidebar} />
            <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />
            <div className="setting-sec">
                <div className="row">
                    <div className="col-lg-3">
                        <div className="feature-box">
                            <img src={image.reports} alt="" />
                            <h6>Reports</h6>
                            <p>Get Reports From Here</p>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="feature-box">
                            <img src={image.vendor} alt="" />
                            <h6>Vendors</h6>
                            <p>10 Vendors</p>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="feature-box">
                            <img src={image.policy} alt="" />
                            <h6>Our Policies</h6>
                            <p>Add or Edit Policies</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Setting;