import React from "react";
import { useState } from 'react'
import Header from '../../component/Header';
import Sidebar from '../../component/SideBar';
import Home from '../../component/Home';
import { image } from "../../common/Theme";

const DashboardPage = () => {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false)

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle)
  }

  return (
    <div className='grid-container'>
      <Header OpenSidebar={OpenSidebar} />
      <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar}/>
      <Home />
    </div>
  )
}

export default DashboardPage;