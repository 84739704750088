import React, { useEffect, useState } from 'react';
import Header from '../../component/Header';
import Sidebar from '../../component/SideBar';
import { image } from "../../../src/common/Theme";
import { Checkbox, FormControl, FormHelperText, Button, Box, Radio } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import Apiconnection from '../../utils/Apiconnection';
import { toast } from 'react-toastify';


const CustomerBooking = () => {
    const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
    const [selectedVendor, setSelectedVendor] = useState('');
    const [userList, setUserList] = useState([]);
    const [loading, setLoading] = useState(false);

    const OpenSidebar = () => {
        setOpenSidebarToggle(!openSidebarToggle);
    };
    const navigate = useNavigate();

    const handleContinue = () => {
        if (selectedVendor) {
          const vendorId = selectedVendor.split('-')[1];
          navigate('/add-booking', { state: { vendorId } });
        } else {
          toast.error('Please select a vendor');
        }
      };

    const getVendorListApi = async () => {
        setLoading(true);
        try {
            const response = await Apiconnection.post('vendor-list');
            if (response?.data?.status) {
                console.log('response?.data?.userList', response?.data?.data?.vendorList);
                setUserList(response?.data?.data?.vendorList);
            }
        } catch (error) {
            console.error('Error fetching price chart data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getVendorListApi();
    }, []);

    const handleCheckboxChange = (event) => {
        console.log(event.target)
        const { id } = event.target;
        setSelectedVendor(prevSelectedVendor => (prevSelectedVendor === id ? '' : id));
        console.log(selectedVendor)
    };


    return (
        <div className='grid-container'>
            <Header OpenSidebar={OpenSidebar} />
            <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />
            <div className='inner-container'>
            <h5 className='top-heading'>Select Vendor / 
  <Link to="/add-booking" style={{ textDecoration: 'none', color: 'blue' }}> Booking Through Prime Park</Link>
</h5>                <div className='vendor-select'>
                    {userList.map((user) => (
                        <div className='vendor-list' key={user.id}>
                            <h3>{user.user.firstName?.charAt(0)}</h3>
                            <FormHelperText>{user.user.firstName}</FormHelperText>
                            <Radio
                                label={user.user.firstName}
                                id={`vendor-${user.id}`}
                                name="vendor"
                                checked={selectedVendor === `vendor-${user.id}`}
                                onChange={handleCheckboxChange}
                            />
                        </div>
                    ))}
                </div>
                <div className='center-btn'>
                    <Button className='submit-btn' onClick={handleContinue}>
                        Continue
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default CustomerBooking;
