import React from "react";
import { useState } from 'react'
import Header from '../../component/Header';
import Sidebar from '../../component/SideBar';
import { image } from "../../common/Theme";

const NotificationsList = () => {
    const [openSidebarToggle, setOpenSidebarToggle] = useState(false)
    
    const OpenSidebar = () => {
        setOpenSidebarToggle(!openSidebarToggle)
    }
 
    return (
        <div className='grid-container'>
            <Header OpenSidebar={OpenSidebar} />
            <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />
           
        </div>
    )
}

export default NotificationsList;