import React, { useState, useEffect } from 'react'
import { BsFillArchiveFill, BsFillGrid3X3GapFill, BsPeopleFill, BsFillBellFill }
  from 'react-icons/bs'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line }
  from 'recharts';
import { image } from "../../src/common/Theme";
import { toast } from "react-toastify";
import Apiconnection from "../utils/Apiconnection";

function Home() {

  const [DashboardCount, setDashboardCount] = useState([]);


  const data = [
    {
      name: 'Page A',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: 'Page C',
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: 'Page D',
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: 'Page E',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Page F',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: 'Page G',
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  useEffect(() => {
    getDashboardCountApi();
}, []);

  const getDashboardCountApi = async () => {
    const payload = {
      parkingGroundId: '1',
    };
    // settableloading(true);
    try {
        const response = await Apiconnection
        .post('slot-analytics', payload);
        console.log('slot-analytics', response)
        if (response?.data?.status) {
            setDashboardCount(response.data.data);
        } else {
            // toast.error(response?.message);
        }
    } catch (error) {
        console.error('Error fetching Agent list:', error);
        // toast.error('Failed to fetch Agent list.');
    } finally {
        // settableloading(false);
    }
};


  return (
    <main className='main-container'>
      <div className='row'>
        <div className='col-lg-8'>
          <div className='main-cards'>

            <div className='card-inner'>
              <div className='card-left'>
                <h6>Total Slots</h6>
                <h3>{DashboardCount.totalSlots}</h3>
                <p style={{color: 'white'}}>.</p>
              </div>
              <div className='card-right'>
              <img src={image.chartimg1} alt="" />
              </div>
            </div>

            <div className='card-inner'>
              <div className='card-left'>
                <h6>Booked Slots</h6>
                <h3>{DashboardCount.totalBookedSlots}</h3>
                <p>{DashboardCount.totalOverstaySlots} overstay</p>
              </div>
              <div className='card-right'>
              <img src={image.chartimg2} alt="" />
              </div>
            </div>
            <div className='card-inner'>
              <div className='card-left'>
                <h6>Free Slots</h6>
                <h3>{DashboardCount.totalFreeSlots}</h3>
                <p style={{color: 'white'}}>.</p>
              </div>
              <div className='card-right'>
              <img src={image.chartimg3} alt="" />
              </div>
            </div>

          </div>
          <div className='charts'>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="pv" fill="#8884d8" />
                <Bar dataKey="uv" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>



          </div>
        </div>
        <div className='col-lg-4'>
        <div className='payment-info'>
        <img src={image.payments} alt="" />
        <div className='payment-status'>
          <p>Payment Received</p>
          <h5>$ 357699</h5>
          </div>
          </div>
        <div className='booking-sec'>
        <div className='booking-header'>
        <h4>New Bookings</h4>
        <a href="#">VIEW ALL</a>
          </div>
        <div className='booking-box'>
        <div className='booking-user'>
          <h4>AG</h4>
          </div>
        <div className='booking-details'>
          <h5>Anastasya Gordon</h5>
          <p>Lorem ipsum dolor sit amet, consectetuer adipiscing dolor elit.</p>
          <div className='booking-time'>
            <p>10m</p>
            <a href='#'><img src={image.action} alt="" /></a>
          </div>
        </div>
          </div>
          <div className='booking-box'>
        <div className='booking-user'>
          <h4>AG</h4>
          </div>
        <div className='booking-details'>
          <h5>Anastasya Gordon</h5>
          <p>Lorem ipsum dolor sit amet, consectetuer adipiscing dolor elit.</p>
          <div className='booking-time'>
            <p>10m</p>
            <a href='#'><img src={image.action} alt="" /></a>
          </div>
        </div>
          </div>
          <div className='booking-box'>
        <div className='booking-user'>
          <h4>AG</h4>
          </div>
        <div className='booking-details'>
          <h5>Anastasya Gordon</h5>
          <p>Lorem ipsum dolor sit amet, consectetuer adipiscing dolor elit.</p>
          <div className='booking-time'>
            <p>10m</p>
            <a href='#'><img src={image.action} alt="" /></a>
          </div>
        </div>
          </div>
          <div className='booking-box'>
        <div className='booking-user'>
          <h4>AG</h4>
          </div>
        <div className='booking-details'>
          <h5>Anastasya Gordon</h5>
          <p>Lorem ipsum dolor sit amet, consectetuer adipiscing dolor elit.</p>
          <div className='booking-time'>
            <p>10m</p>
            <a href='#'><img src={image.action} alt="" /></a>
          </div>
        </div>
          </div>
          
        </div>
        </div>
      </div>
    </main>
  )
}

export default Home